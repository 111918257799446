import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import USERS from '../../common/data/userDummyData';
import { demoPages, extraMenu } from '../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import { useMst } from '../../models';
import { useTranslation } from 'react-i18next';
import { removeItem } from '../../lib/localstorage';
import AuthService from '../../services/AuthService';
import { observer } from 'mobx-react';
import Avatar from '../../components/Avatar';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} onError={`this.src='${USERS.SAM.srcSet}'`} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = observer(() => {
	const navigate = useNavigate();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { user, company } = useMst();
	const { t } = useTranslation(['translation', 'menu']); 

/* 	useEffect(() => {
		if (!user.id) {
			window.location.replace('/auth-pages/login');
		}
	}, [user]); */

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<Avatar
						srcSet={user.me.profile}
						src={user.me.profile}
						userName={`${company?.nickName || user?.name || ''}`}
						size={30}
						color={'#e7e7e7'}
						textSizeRatio={10}
						maxInitials={2}
						fgColor={'dark'}
					/>
					<div className='user-info ms-3'>
						<div className='user-name'>{`${company?.nickName || user?.name || ''}`}</div>
						<div className='user-sub-title'>{t(company?.userRank) || ''}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				{/* <DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() => navigate(`../${extraMenu.profile.path}`)}>
						{t('Profile')}
					</Button>
				</DropdownItem> */}
				<DropdownItem>
					<Button icon={darkModeStatus ? 'LightMode' : 'DarkMode'} onClick={() => setDarkModeStatus(!darkModeStatus)} aria-label='Toggle fullscreen'>
						{darkModeStatus ? t('화이트모드') : t('다크모드')}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button icon='Repeat' onClick={() => navigate(`../${extraMenu.changeCompany.path}`)}>
						{t('회사변경')}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button
						icon='Logout'
						onClick={async () => {
							AuthService.logout().then(async () => {
								await Promise.all([
									removeItem('access-token'),
									removeItem('refresh-token'),
									removeItem('rootState'),
									removeItem('webToken'),
									removeItem('authUser'),
									user.logout(),
									company.logout(),
								]).then(() => {
									navigate(`/${demoPages.login.path}`);
								});
							});
						}}>
						{t('로그아웃')}
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
});

export default User;
