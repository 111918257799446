 import React, { useContext, useEffect, useRef, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import { useMst } from '../../../models';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import firebaseInit from '../../../firebaseInit';
import firebase from 'firebase/app';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../components/bootstrap/Toasts';
import { demoPages, layoutMenu, extraMenu, pawsswordFind, adminsMenu, noPaymentPage } from '../../../menu';
import { observer } from 'mobx-react';
import AuthService from '../../../services/AuthService';
import { browserName, browserVersion, osVersion, osName } from "react-device-detect";
import Alert from '../../../components/bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import CompanyService from '../../../services/CompanyService';
import ThemeContext from '../../../contexts/themeContext';
import { Base64 } from 'js-base64';
import PaymentService from '../../../services/PaymentService';
import { useCard } from '../../AuthMenu/Company/CardContext';
import { useTimeout } from '../../../../src/lib/Util';

const DashboardHeader = observer(() => {
	const {checkPinatState, setCheckPinatState, planList, setPlanList} = useCard();
	const navigate = useNavigate();
	const location = useLocation();
	const { user, company, notifiction, navtabmenus } = useMst();
	const { setAsdieSubMenuStatus, setAsideMenu, setEApproval, eApproval } = useContext(ThemeContext);
	const { t } = useTranslation(['translation', 'menu']);

	const refreshPage = () => {
		getPinatState();
		fetchPlanList();
	  };
	
	  const calculateTimeout = () => {
		const now = new Date();
		const then = new Date();
		
		// 자정 30초에 페이지 새로고침 설정
		if (now.getHours() > 0 || (now.getHours() === 0 && now.getMinutes() > 0) || (now.getHours() === 0 && now.getMinutes() === 0 && now.getSeconds() >= 0)) {  
			then.setDate(now.getDate() + 1);
		}
		then.setHours(0);
		then.setMinutes(0);
		then.setSeconds(0);
	
		return then.getTime() - now.getTime();
	  };
	
	useTimeout(refreshPage, calculateTimeout());

	useEffect(()=> {
		const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path, extraMenu.changeCompany.path, pawsswordFind.PasswdReset.path];

		if (checkPinatState?.state === 'TRIAL_TERMINATED' || checkPinatState?.state === 'TERMINATED') {

			const isoutPage = withOutAsidePages.some(v => '/' + v === location.pathname); 
			const isPaymentPage = location.pathname === adminsMenu.setting.subMenu.paymentPage.path; 

			if (!isoutPage && !isPaymentPage) {
				if(checkPinatState?.isAdmin) {
					navigate(adminsMenu.setting.subMenu.paymentPage.path);
				} else {
					navigate(noPaymentPage.NoPaymentPage.path);
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, checkPinatState]);

	let messaging = null;
	if (firebase.messaging.isSupported()) {
		messaging = firebaseInit.messaging();

		messaging.onMessage(function (payload) {
			//console.log("onMessage", payload);	
			// alarm 에 보이도록 !!!
			// refresh Notification
			reFreshMsg();
			addToast(
				<Toasts
					icon={'InfoOutline'}
					iconColor={'info'}
					title={t((payload.data?.title ? payload.data?.title : payload.notification.title), payload.data)}
					/* isDismiss */
					>
					<>
						{/* <p>{t((payload.data?.message ? payload.data?.message : payload.notification.body), payload.data)}</p> */}
						 <p>{payload.data?.message ? payload.data?.message : payload.notification.body}</p> 
						{payload?.data?.detailId && <Button type='button' size='xs' onClick={()=>{
							const cryptoUrl = Base64.encode(`${company.get.id}_${payload?.data?.detailId}`);
							window.location.href = `/e-approval/document/${cryptoUrl}`;
						}} ><small>링크</small></Button>}
					</>
				</Toasts>,
				{
					autoDismiss: false,
				}
			)
			/* showNotification(
				payload.notification.title,
				payload.notification.body,
				'info'
			) */ 
			// notification refresh 
		});		


			// messaging.setBackgroundMessageHandler(function(payload) {
			// 	console.log('[firebase-messaging-sw.js] Received background message', payload);
			// 	return new Promise(function(resolve, reject) {
			// 		resolve();
			// 		setTimeout(function(){
			// 			self.registration.getNotifications().then(notifications => {  
			// 				notifications.forEach((notification) => { 
			// 					notification.close();
			// 				})
			// 			})
			// 		},10);
			// 	});
			// });

			  
		// messaging.setBackgroundMessageHandler(function (payload) {
		// 	console.log("onBackgroundMessage", payload);
		// 	const notificationTitle = t(payload.notification.title, payload.data);
		// 	const notificationOptions = {
		// 		body: t(payload.notification.body, payload.data)
		// 	}
		// 	return self.registration.showNotification(notificationTitle, notificationOptions);
		// });

	}

	const { addToast } = useToasts();
	
	const reFreshMsg = async () => {
		notifiction.getRefresh(company.get.id);
		CompanyService.getEApproval(company.get.id, company.get.isDoc ? 0 : user.me.id).then( res => {
			if(res.data) {
				let r = {};
				Object.keys(res.data)?.map( v => {
					r[v] = res.data[v]?.length || 0		
				})
				setEApproval(r);
			}			
		})
	}

	function useInterval(callback, delay) {
		const savedCallback = useRef();
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);
	
		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => clearInterval(id);
			}
		}, [delay]);
	}

	const checkSession = async () => {					
		// console.log('check Session');		
		await AuthService.check().then( async res => {
			await notifiction.getRefresh(company.get.id)
			if(res.data === undefined) {
				navigate(`/${demoPages.login.path}`)
			}
		})
	};		

	useInterval(() => {
		checkSession();
		// getPinatState(); 아래에서 감지 가능하면 이건 안해도 될듯하다..?
	}, 1000 * 60 * 5)
	// }, 1000)
	
	useEffect(()=>{		
		reFreshMsg();
		//checkSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	// const [planList, setPlanList] = useState([]);
	const [loading, setLoading] = useState(false);
  const [pinatState, setPinatState] = useState(null);

	const fetchPlanList = async () => {
		try {
			const getPlanInfo = await PaymentService.getPlan();
			let planList = getPlanInfo?.data;
			setPlanList(planList);
			// console.log('planList', planList);
		} catch (error) {
			console.error('Error fetching plan info:', error);
		}
	};

	const getPinatState = async () => {
    setLoading(true);
    const pinatStateResponse = await PaymentService.getUpdatePinatState();

    setPinatState(pinatStateResponse);
    const stateType = pinatStateResponse?.data?.pinatState?.includes('TRIAL') 
      ? '무료기간' 
      : pinatStateResponse?.data?.pinatState === 'CANCEL_PENDING' 
        ? '핀앳 만료일' 
        : '';

    const result = {
      state: pinatStateResponse?.data?.pinatState || 'TERMINATED',
      countDay: pinatStateResponse?.data?.counting || 0,
      type: stateType,
			isAdmin: pinatStateResponse?.data?.isAdmin || false,
			paymentDate: pinatStateResponse?.data?.paymentDate,
			terminateDate: pinatStateResponse?.data?.terminateDate,
    };
    
    setCheckPinatState(result);
		company.setData({pinatState: pinatStateResponse?.data?.pinatState})
    setLoading(false);
  }

  useEffect(() => {
    getPinatState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

	useEffect(()=> {
		refreshPage();
			// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
		<>
			<Header>
				<HeaderLeft className={'text-truncate'}>
					{((checkPinatState?.state === 'ACTIVE' || checkPinatState?.state === 'TRIAL_PAY_PENDING' || checkPinatState?.state === 'TRIAL_PAY_COMPLETED' || checkPinatState?.state === 'CANCEL_PENDING')) && navtabmenus?.get?.list.length > 0 ? (
						<div className='col-md overflow-auto me-5'>
							{navtabmenus?.get?.list?.map((item) => (
								<ButtonGroup key={item.id}>
									<Button
										type='button'
										size='sm'
										color={item.isActive === true ? 'light' : (location.pathname === '/'+item.path) == true ? 'light' : ''}
										onClick={() => {
											let lists = navtabmenus?.get?.list?.map( v => {
												return {...v, isActive: item.path === v.path ? true : false}
											})
											navtabmenus.setMenus(lists);
											navigate(item.path[0] === '/' ? item.path : '/' + item.path);
										}}
										className='text-nowrap'>
										{item.name}
									</Button>
									<Button
										type='button'
										size='sm'
										color={item.isActive === true ? 'light' : (location.pathname === '/'+item.path) == true ? 'light' : ''}
										onClick={async () => {
											let lists = navtabmenus.get.list?.filter((v) => v.id !== item.id);
											navtabmenus.setMenus(lists);
										}}>
										<Icon icon='close' />
									</Button>
								</ButtonGroup>
							))}
						</div>
					) : (
						<></>
					)}
				</HeaderLeft>
				<CommonHeaderRight planList={planList} checkPinatState={checkPinatState} loading={loading} /* afterChildren={<CommonHeaderChat />} */ />
			</Header>
			{browserName.includes('Safari') && (
				<Alert icon='infoOutline' className={'mx-5'} rounded={0}>
					Safari 브라우저에서는 푸시알림이 지원되지 않습니다. Chrome, Edge 브라우저를 이용해주세요
				</Alert>
			)}
		</>
	);
});

export default DashboardHeader;

