import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import showNotification from '../../../../components/extras/showNotification';
import Modal, { ModalHeader, ModalBody, ModalTitle, ModalFooter } from '../../../../components/bootstrap/Modal';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Icon from '../../../../components/icon/Icon';
import Label from '../../../../components/bootstrap/forms/Label';
import { priceFormat, maskCardNumber } from '../../../../lib/Util';
import Badge from '../../../../components/bootstrap/Badge';
import CompanyService from '../../../../services/CompanyService';
import { adminsMenu } from '../../../../menu';
import moment from 'moment';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentService from '../../../../services/PaymentService';
import PaymentEditModal from '../../../AuthMenu/Company/components/PaymentEditModal';
import StateAddCardModal from '../../../AuthMenu/Company/components/StateAddCardModal';
import { useCard } from '../../../AuthMenu/Company/CardContext';
import classNames from 'classnames';

const Payment = ({ isModalOpen, setIsModalOpen, checkPinatState }) => {
  const { currentCardInfo, setCurrentCardInfo } = useCard();

	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const { company, user } = useMst();
	const navigate = useNavigate();
	const location = useLocation();
	const [planOptionList, setPlanOptionList] = useState([]);
	const [companyInfo, setCompanyInfo] = useState(null);
	const [planDetails, setPlanDetails] = useState(null);
	const totalAmount = ((planDetails?.price * 0.1) + planDetails?.price) * companyInfo?.userCount; 
	const [currentStep, setCurrentStep] = useState(1);
  const [cardList, setCardList] = useState([]);
	// const [currentCardInfo, setCurrentCardInfo] = useState(null);
  const [planInfo, setPlanInfo] = useState(null);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [modalState, setModalState] = useState({ isOpen: false, type: null, selectedCard: null });
	const [openAddCardModal, setOpenAddCardModal] = useState({ isOpen: false, success: true });

	const [checks, setChecks] = useState({
		checkAll: false,
		checkA: false,
		checkB: false,
	});
	
	const btnStyle = {
		border: '1px solid rgba( 222, 226, 230, 0.5 )', borderRadius: 7, padding: 10
	}

	const paymentInfo = useFormik({
		initialValues: {
			userCount: 0,
			companyName: '',
			companyNumber: '',
			companyId: company.id,
			adminId: user.id,
			adminName: user.me?.name,
			adminEmail: user.me?.email,
			amount: 0,
		},
		validate: (values) => {
			const errors = {};

			return errors;
		},
		onSubmit: async (values) => {
			requestPayment(values);
		},
	});

	// const refresh = async () => {
	// 	await CompanyService.info({ id: company.id }).then((response) => {
	// 		let infos = { ...response.data };
	// 		if (infos.restDate === null) {
	// 			infos.restDate = infos.restDate = ['01', '01'];
	// 		} else {
	// 			infos.restDate = infos.restDate.split('-');
	// 		}
	// 		// console.log('infos : ', infos)
	// 		setCompanyInfo(infos);
	// 	});
	// };

	useEffect(() => {
		getCompanyPlan();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCompanyPlan = async (type) => {
		const getplan = await PaymentService.getType();
		setCompanyInfo(getplan?.data);

	};

	const formatCompanyNumber = (number) => {
		const cleanedNumber = number.replace(/[^0-9]/g, '');
		const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
		return formattedNumber;
	};

	const requestPayment = async (values) => {
		const cardDetails = {
			userCount: companyInfo?.userCount, // 사람 수
			planType: 1 // 0:연간, 1:월간
	};

		await PaymentService.requestPayment(cardDetails).then((response) => {
			if (response) {
				// window.location.href = response.data.online_url;
				window.open(response.data.online_url);
				// showNotification(t('결제 완료'), t('결제 완료 하였습니다'), 'info');
				// refresh();
				// setIsModalOpen(false);
				// setIsSelectModalOpen(false);
				// setSuccessAdd(true);
			} else {
				showNotification(t('카드 등록'), t('카드 등록 실패 하였습니다'), 'danger');
			}
		});
	};

	const updateType = async () => {
		const value = {
			planType: 'month', // month : 월간, year : 연간 
			payType: 't'
		};
		await PaymentService.updatePlanType(value).then((response) => {
			if (response?.data?.message === "updated successfully") {
				setCurrentStep(3);
				// company.setData({payType: 't', planType: 'month', pinatState: 'ACTIVE'});
				// console.log('response', response)
			} else {
				showNotification(t('결제 신청 실패'), t('결제 신청 실패 하였습니다'), 'danger');
			}
		});
	};

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.data && event.data.status === 'success') {
				// console.log(event.data.message); // 성공 메시지 알림
				fetchCardList();
				openSuccessModal(true);
			} else if (event.data && event.data.status === 'fail') {
				openSuccessModal(false);
				// console.log(event.data.message); // 실패 메시지 알림
			}
		};
		window.addEventListener('message', handleMessage, false);
		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('message', handleMessage, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let list = [...cardList];

		if (currentCardInfo) {
			list?.map((i, indexs) => {
				if (currentCardInfo?.card_info === i?.card_info) {
					let cardToMove = list[indexs];
					const index = list?.indexOf(cardToMove);
					if (index > -1) {
						const [card] = list.splice(index, 1);
						list.unshift(card);
					}
					setCardList(list);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCardInfo]);

	const updateDefaultCardType = async (card) => {
		await PaymentService.updateDefaultCard(card);
	}

	const openModal = (type, card) => {
		setModalState({ isOpen: true, type, selectedCard: card });
	};
	const closeModal = () => {
		setModalState({ isOpen: false, type: null, selectedCard: null });
	};
	const openSuccessModal = (type) => {
		setOpenAddCardModal({ isOpen: true, success: type });
	};
	const closeSuccessModal = () => {
		setOpenAddCardModal({ isOpen: false, success: false });
	};


	const handleNextButtonClick = (card) => {
		setCurrentStep(currentStep + 1);
		let discountPrice = card?.price - card?.price * (card?.discount / 100); 
		setPlanDetails({ name: card?.plan, price: discountPrice });
	};

	const resetDataForm = () => {
		setCurrentStep(1);
		paymentInfo.resetForm();
		setChecks({
			checkAll: false,
			checkA: false,
			checkB: false,
		});
	};

	const fetchCardList = async () => {
		try {
			const getCardList = await PaymentService.getCardInfo();
			const getPlanInfo = await PaymentService.getPlan();

			let cardList = getCardList?.data;
			let planList = getPlanInfo?.data;
			planList?.sort((a,b) => {return a.id > b.id ? -1 : 1}); // 문의보다는 페이하는 월간을 앞으로 순서 변경
			setPlanOptionList(planList);

			// 중복된 card_info 제거
			const uniqueCardList = cardList.reduce((acc, current) => {
				const existing = acc.find(item => item.card_info === current.card_info);
				if (!existing) {
						acc.push(current);
				} else if (new Date(existing.createdAt) < new Date(current.createdAt)) {
						// createdAt이 더 최신인 경우 교체
						const index = acc.indexOf(existing);
						acc[index] = current;
				}
				return acc;
			}, []);

			setCardList(uniqueCardList);
			let currentCard = uniqueCardList?.find(i => !!i?.type);
			setCurrentCardInfo(currentCard);
			setPlanInfo(planList[0]);
		} catch (error) {
			console.error('Error fetching card info:', error);
		}
	};

	useEffect(() => {
		fetchCardList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	// useEffect(() => {
	// 	if (checks.checkAll) {
	// 		setChecks({ ...checks, checkA: true, checkB: true });
	// 	} else if (!checks.checkAll && checks.checkA && checks.checkB) {
	// 		setChecks({ ...checks, checkA: false, checkB: false });
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [checks.checkAll]);

	// useEffect(() => {
	// 	if (checks.checkAll && (!checks.checkA || !checks.checkB)) {
	// 		setChecks({
	// 			...checks,
	// 			checkAll: false,
	// 		});
	// 	} else if (checks.checkA && checks.checkB) {
	// 		setChecks({
	// 			...checks,
	// 			checkAll: true,
	// 		});
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [checks.checkA, checks.checkB]);

	useEffect(() => {
		if (isModalOpen) {
			resetDataForm();
			fetchCardList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalOpen]);

	const step1Content = (
		<div className='row'>
			{planOptionList?.map((card, index) => {

			let discountPrice = card?.price - card?.price * (card?.discount / 100);
				return (
					<Card className='col-6' shadow={'none'} key={index}>
						<CardBody className={`border rounded ${card?.id === 1 ? 'border-light' : 'border-info'}`}>
							<div>
								<span className={`fs-4 mb-5 fw-bold ${card?.id === 2 && 'text-info' }`}>{t(card?.plan)}</span>
								<span className='mx-2'>{card?.id === 2 && t('매월 정기결제')}</span>
							</div>
							<div className='my-3 text-decoration-line-through text-muted'>
								<span>{priceFormat(card?.price) + '원'}&nbsp;/&nbsp;{t('월별, 인당')}</span>
							</div>
							{card?.discount > 0 && <div className='text-info fw-bold lh-1'>{card?.discount + '% 할인'}</div>}
							<div className='d-flex align-items-center'>
								{/* {card?.id === 1 && (
									<>
										<Badge
											color='#E8ECFF'
											style={{
												backgroundColor: '#E8ECFF',
												color: '#556EE6',
												borderRadius: 4,
												padding: 6,
												fontSize: 9,
											}}
											size={13}>
											{t('추천')}
										</Badge>
										&nbsp;&nbsp;&nbsp;
									</>
								)} */}
								<span className='fs-3 fw-bold'>{'₩ ' + priceFormat(discountPrice)}</span>
							</div>

							{/* <div className='d-flex px-1'> */}
								{/* {card?.discount > 0 ? (
									<>
										<div className='text-info fw-bold'>{card?.discount + '%'}</div>&nbsp;&nbsp;&nbsp;
									</>
								) : null} */}
								{/* <div>{t('월별, 인당 (VAT별도)')}</div> */}
								<div>{t('1인 기준 / 월 (VAT별도)')}</div>
							{/* </div> */}

							<div className='mt-4'>
								<Button
									color={card?.id === 2 && 'info'}
									rounded={1}
									style={{
										width: '100%',
										backgroundColor: card?.id !== 2 && '#556EE64D',
										color: 'white'
									}}
									disabled={card?.id === 1 ? true : false}
									type={'button'}
									onClick={() => {
										handleNextButtonClick(card);
									}}>
									{t(card?.id === 1 ? '문의하기' : '시작하기')}
								</Button>
							</div>
						</CardBody>
					</Card>
				);
			})}
			<div>
				<Icon icon='ConfirmationNumber' className='mx-3' />
				<span>{t('전자결재를 포함한 모든 기능을 합리적으로 이용해보세요') + '.'}</span>
				<hr style={{ color: '#D9D9D9' }} />

				<div className='px-2 py-3 rounded-1' style={{ background: '#D9D9D933' }}>
					<div className=''>
						<Icon icon='Done' color='info' className='mx-3' /> <span>{t('출퇴근 관리')}</span>
					</div>

					<div className=''>
						<Icon icon='Done' color='info' className='mx-3' /> <span>{t('초과근무 관리')}</span>
					</div>

					<div className=''>
						<Icon icon='Done' color='info' className='mx-3' /> <span>{t('GPS 및 WIFI 출퇴근 지정')}</span>
					</div>

					<div className=''>
						<Icon icon='Done' color='info' className='mx-3' /> <span>{t('대리 출퇴근 방지')}</span>
					</div>

					<div className=''>
						<Icon icon='Done' color='info' className='mx-3' /> <span>{t('전자 결재 관리')}</span>
					</div>

					<div className=''>
						<Icon icon='Done' color='info' className='mx-3' /> <span>{t('결재라인 고급(병렬, n차) 관리')}</span>
					</div>
				</div>
			</div>
		</div>
	);

	const step2Content = (
		<form onSubmit={paymentInfo.handleSubmit}>
			<div className='row'>
				<div className='col-md-7'>
					<Card shadow='none'>
						{/* 결제 정보 */}
						<CardBody>
							<div className='row'>
								{/* 왼쪽 컬럼 */}
								<div className='row pb-3 border-bottom'>
									<FormGroup>
										<div className='row align-items-center'>
											<Label className={'col-6 fw-bold'}>{t('사용자 수')}</Label>
											<div className='col-6 d-flex align-items-center'>
												<div className='col-8 me-3'>
													<Input
														id={'userCount'}
														name={'userCount'}
														type={'number'}
														style={btnStyle}
														// value={paymentInfo.values.userCount}
														value={companyInfo?.userCount || 0}
														disabled
														min={0}
														onBlur={paymentInfo.handleBlur}
														onChange={(e) => {
															paymentInfo.setFieldValue('userCount', e.target.value);
														}}
														// style={{ borderRadius: 7 }}
													/>
												</div>
												<div className='col-4'>
													<span className='fw-bold'>{t('명')}</span>
													{/* <Icon icon='infoOutline' className='ms-2' /> */}
												</div>
											</div>
										</div>
									</FormGroup>
								</div>

								<div className='row pb-3 mt-3 border-bottom'>
									<FormGroup>
										<div className='row'>
											<Label className={'fw-bold d-flex justify-content-between'}><span>{t('결제수단')}</span>
											<div
												className='text-info cursor-pointer'
												onClick={() => {
													// requestPayment(); //open payLetter popup
													setCurrentStep(4);
												}}>
												<Icon icon='Add' />
												<span className='fw-normal text-decoration-underline'>{t('결제 수단 변경')}</span>
											</div></Label>
											{!currentCardInfo ? 
											<div className='justify-content-center'>
												<Button
													color='info'
													isOutline
													className='py-3 my-2'
													style={{ width: '95%' }}
													rounded={1}
													onClick={() => {
														paymentInfo.handleSubmit();
													}}>
													{t('등록하기')}
												</Button>
											</div> : 
										  <div className="card-info-block text-center p-5 border">
												<div>{maskCardNumber(currentCardInfo?.card_info || '')}</div>
											</div>
											}
										</div>
									</FormGroup>
								</div>

								<div className='row pb-3 mt-4'>
									<FormGroup>
										<div className='row align-items-center'>
											<Label className={'col-6 fw-bold'}>{t('사업장 이름')}</Label>
											<div className='col-6 d-flex align-items-center'>
												<div className=''>
													<Input
														id={'companyName'}
														style={btnStyle}
														name={'companyName'}
														type={'text'}
														value={companyInfo?.name || ''}
														disabled
														// value={paymentInfo.values.companyName}
														onBlur={paymentInfo.handleBlur}
														onChange={paymentInfo.handleChange}
													/>
												</div>
											</div>
										</div>
									</FormGroup>
								</div>
								<div className='row pb-3'>
									<FormGroup>
										<div className='row align-items-center'>
											<Label className={'col-6 fw-bold'}>{t('담당자 이름')}</Label>
											<div className='col-6 d-flex align-items-center'>
												<div className=''>
													<Input
														id={'adminName'}
														name={'adminName'}
														type={'text'}
														value={user.me?.name}
														disabled
														// value={paymentInfo.values.adminName}
														onBlur={paymentInfo.handleBlur}
														onChange={paymentInfo.handleChange}
														style={btnStyle}
													/>
												</div>
											</div>
										</div>
									</FormGroup>
								</div>
								<div className='row pb-3'>
									<FormGroup>
										<div className='row align-items-center'>
											<Label className={'col-6 fw-bold'}>{t('담당자 이메일')}</Label>
											<div className='col-6 d-flex align-items-center'>
												<div className=''>
													<Input
														id={'adminEmail'}
														name={'adminEmail'}
														type={'text'}
														// value={paymentInfo.values.adminEmail}
														value={user.me?.email}
														disabled
														onBlur={paymentInfo.handleBlur}
														onChange={paymentInfo.handleChange}
														style={btnStyle}
													/>
												</div>
											</div>
										</div>
									</FormGroup>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
				<div className='col-md-5'>
					<Card
						shadow='sm'
						borderSize={1}
						borderColor='light'
						className='rounded'
						// style={{ backgroundColor:'#EFF1FC80'}}
					>
						<CardBody>
							<div className='row '>
								<div className='col-md-12'>
									{/* 왼쪽 컬럼 */}
									<div className='row fw-bold'>
										<span className='fw-bold fs-5'>{t('Total')}</span>
										<div className='border-bottom border-2 py-3'>
											<div className='col-sm-12 d-flex justify-content-between'>
												<span>{planDetails?.name || ''}</span>
												<span className='text-right'>{'₩ ' + priceFormat(planDetails?.price || 0)}</span>
											</div>
										</div>
										<div className='border-2 border-bottom py-3'>
											<div className='py-3'>
												<div className='col-sm-12 d-flex justify-content-between'>
													<div className='align-items-center'>
														<span>{t('사용자 수')} {companyInfo?.userCount + t('명')}</span>
														<Tooltips
															title={<div className='ms-2 my-3 text-start'><span className='fw-bold'>{t('현재 사용자 수 기준')}</span>&nbsp;<span>{t('예상 금액이며,')}</span><br/><span>{t('사용자 수 변동에 따라 금액이 변경될 수 있습니다') + '.'}</span></div>}
														>
															<Icon icon='InfoOutline' className='mx-2'/>
														</Tooltips>
													</div>
													<span className='text-right'>{'₩ ' + priceFormat(totalAmount)}</span>
												</div>
												<span className='d-flex justify-content-end' style={{ color: '#D9D9D9' }}>
													{t('(VAT 포함)')}
												</span>
											</div>
										</div>
									</div>
									<div className='agree-check-block row mb-2'>
										{/* <Checks
											className='my-3 mx-1 border-light'
											type='check'
											name='agree'
											label={<span className='fw-bold'>{t(`모두 동의합니다`)}</span>}
											checked={checks.checkAll}
											onChange={(e) => {
												setChecks({ ...checks, checkAll: e.target.checked });
											}}
										/> */}
										<hr style={{ color: '#D9D9D9' }} />
										<Checks
											type='check'
											size='sm'
											className='my-2 mx-1 border-light'
											name='agree'
											label={
												<>
													<span>{t(`핀앳`)}</span>&nbsp;
													<a
														href='//pinat.co.kr/terms.html'
														target={'_blank'}
														rel='noopener noreferrer'
														className={classNames('link-light text-decoration-none text-info', {
															'link-light': false,
															'link-light': true,
														})}>
														{t('이용약관')}
													</a>
													<span>{t('에 동의합니다')}</span>
												</>
											}
											checked={checks.checkA}
											onChange={() => {
												setChecks({ ...checks, checkA: !checks.checkA });
											}}
										/>
										{/* <Checks
											className='my-2 mx-1 border-light'
											type='check'
											name='agree'
											label={
												<>
													<span className='text-info'>{t(`전자금융 이용약관`)}</span>
													<span>{t('에 동의합니다')}</span>
												</>
											}
											checked={checks.checkB}
											onChange={() => {
												setChecks({ ...checks, checkB: !checks.checkB });
											}}
										/> */}
									</div>
									<div className='my-4 px-3'>
										<div className='text-muted mb-3' style={{ fontSize: 10 }}>
											*&nbsp;&nbsp;&nbsp;{t('무료 체험기간은 30일 이후에 종료되며, 체험판이 종료된 후 요금제를 해지할때까지 매월 영업일 기준 1일에 결제요금이 청구됩니다') + '.'}
										</div>
										<div className='text-muted' style={{ fontSize: 10 }}>
											*&nbsp;&nbsp;&nbsp;{t('가격 정책은 후불 결제로, 체험 만료일 기준 익월 1일에 결제 요금이 청구됩니다') + '.'}
										</div>
									</div>
									<div className='mb-5'>
										<Button
											type={'button'}
											rounded={1}
											className='py-3'
											style={{ width: '100%', backgroundColor: checks.checkA ? '#556EE6' : '#D9D9D9' }}
											// style={{ width: '100%', backgroundColor: checks.checkAll ? '#556EE6' : '#D9D9D9' }}
											// disabled={!checks.checkAll}
											disabled={!checks.checkA}
											onClick={() => {
												if(cardList?.length > 0 && !!currentCardInfo){
													updateType();
												} else {
													showNotification(t('결제 신청'), t('카드를 등록해주세요'), 'danger');
												}
											}}>
											<span className={'text-white'}>{t('결제 신청하기')}</span>
										</Button>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</form>
	);

	const step3Content = (
		<div className='text-center'>
			<div>
				<div className='my-4'>
					<Icon icon='CheckCircleOutline' size='3x' color='info'/>
				</div>
				<div className='my-4 fw-bold fs-4'>
					{t('결제 신청 완료')}
				</div>
				{/* <div className='mb-3 fw-bold fw-5'>
					{t(`${moment().format('YYYY년 M월 DD일')}까지 이용할 수 있어요`)}
				</div> */}
				<div className='mb-4 text-muted'>
					{t('결제 예정일')+' : '}
					{checkPinatState?.paymentDate || ''}
				</div>
				{/* <div className='mb-4 text-muted'>{t(`결제 내역은[${adminsMenu.setting.text}>${adminsMenu.setting.subMenu.paymentPage.text}]에서 확인이 가능해요`)}</div> */}
			</div>
			<div className='mb-1'>
				<Button className='px-4' color='info' rounded={0} onClick={()=> {
					console.log('location L ', location)
					if(location?.pathname === adminsMenu.setting.subMenu.paymentPage.path) {
						window.location.reload();
					} else {
						navigate(adminsMenu.setting.subMenu.paymentPage.path);
						setIsModalOpen(false);
					}
				}
			}>{t('자세히 보기')}</Button>
			</div>
		</div>
	);

	const step4Content = (
		<PaymentEditModal 
			cardList={cardList}
			setCardList={setCardList}
			setCurrentCardInfo={setCurrentCardInfo}
			currentCardInfo={currentCardInfo}
			companyInfo={companyInfo}
			fetchCardList={fetchCardList}
			/>
	);

	const _contents = currentStep === 1 ? step1Content : currentStep === 2 ? step2Content : currentStep === 3 ? step3Content : currentStep === 4 ? step4Content : null;

	return (
		<>
			<Modal
				isOpen={isModalOpen}
				setIsOpen={setIsModalOpen}
				isCentered={true}
				isScrollable={true}
				isStaticBackdrop={true}
				size={currentStep === 1 ? 'md' : currentStep === 2 ? 'lg' : currentStep === 3 ? 'md' : null}>
				{currentStep !== 3 && (
					<ModalHeader setIsOpen={setIsModalOpen}>
						<div className='p-1'>
							{currentStep !== 4 && <ModalTitle>{t('Pinat Lite 구매하기')}</ModalTitle>}
							<div className='mt-1'>
								<span>{t(currentStep === 1 ? '1단계 | 플랜 선택' : currentStep === 2 ? '2단계 | 결제 정보 입력' : '')}</span>
							</div>
						</div>
					</ModalHeader>
				)}
				<ModalBody className='mx-3 my-2'>{_contents}</ModalBody>
				<ModalFooter className='d-flex justify-content-start'>
					{(currentStep === 2 || currentStep === 4) && (
						<ButtonGroup>
							<Button
								style={{border: '1px solid rgba( 222, 226, 230, 0.5 )'}}
								isOutline
								onClick={() => {
									if(currentStep === 2){
										resetDataForm();
									} else {
										setCurrentStep(2);
										setOpenAddCardModal({isOpen: false, success: true});
									}
								}}>
								<span className='text-dark'>{t('이전')}</span>
							</Button>
						</ButtonGroup>
					 )} 
				</ModalFooter>

				{currentStep === 2 && openAddCardModal.isOpen &&
				<StateAddCardModal openAddCardModal={openAddCardModal} closeSuccessModal={closeSuccessModal} />
				}				

			</Modal>
		</>
	);
};

export default Payment;
