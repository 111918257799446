import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCUXtTbRCmgvchMjIAVnoMpFB6NaMgK3SI",
    authDomain: "pinat-eb6f2.firebaseapp.com",
    projectId: "pinat-eb6f2",
    storageBucket: "pinat-eb6f2.appspot.com",
    messagingSenderId: "2596862216",
    appId: "1:2596862216:web:39378c9b0529e3fba9d4bd",
    measurementId: "G-2XR62C8FND"
};

export default firebase.initializeApp(firebaseConfig);