import React, {useState, useEffect} from "react";
import Modal, {ModalBody, ModalFooter} from "../../../../components/bootstrap/Modal";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import { useTranslation } from "react-i18next";

const StateAddCardModal = (props) => {

  const {openAddCardModal, closeSuccessModal} = props;
  const {t} = useTranslation(['translation, menu']);

  return (
    <Modal size={'sm'} isOpen={openAddCardModal.isOpen} toggle={closeSuccessModal} isCentered>
    <ModalBody className='text-center mt-4'>
      <div className='mb-5'>
        <Icon icon='CreditCard' size='3x' color={openAddCardModal.success ? 'info' : 'danger'} />
      </div>
      {openAddCardModal.success ? (
        <div className='fw-bold fs-4 my-4'>
          <span>{t('카드 등록이')}</span>&nbsp;
          <span className='text-info'>{t('완료')}</span>
          <span>{t('되었습니다') + '.'}</span>
        </div>
      ) : (
        <div className="my-4">
          <div className='fw-bold fs-4 mb-2'>
            <span>{t('카드 등록에')}</span>&nbsp;
            <span className='text-danger'>{t('실패')}</span>
            <span>{t('하였습니다') + '.'}</span>
          </div>
          <div className='text-muted'>
            <span>{t('카드 정보를 다시 입력해주세요') + '.'}</span>&nbsp;
          </div>
        </div>
      )}
    </ModalBody>
    <ModalFooter className='d-flex justify-content-center mb-4'>
      <Button className='px-5' rounded={1} color='info' onClick={closeSuccessModal}>
        {t('확인')}
      </Button>
    </ModalFooter>
  </Modal>
  )
}
export default StateAddCardModal;