import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<svg
			width={height !== 408 ? height * (1005 / 408) : width}
			height={width !== 1005 ? width * (408 / 1005) : height}
			viewBox='0 0 1005 408'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>				

<image id="image0" width="1005" height="408" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA+0AAAGYCAMAAAAA6fN6AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAZlBMVEX///9Pa7FPa7FPa7FP
a7FPa7FPa7FPa7FPa7FPa7FPa7FPa7FPa7FPa7FPa7FPa7HpYXfpYXfpYXfpYXfpYXfpYXfpYXfp
YXfpYXfpYXfpYXfpYXfpYXfpYXfpYXdPa7HpYXf///8AFO7/AAAAH3RSTlMAIEAQYICgwODQkFAw
sPBwMEBwgGAQUJDA8LCg4NAg3OObCQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAAFxEAABcRAcom8z8A
AAAHdElNRQfmAxALNA3Svh/bAAAo9ElEQVR42u2d23bbOBJFI151Z9Lp9HQ6M23//1eOZNmxZJMi
qk4VLtTZTzOrVywCxCaAQgH48oUQMsaq+k3qRyGEmFJXVdO2Xd/366dRNqf/tG3bXbVP/aiEEBX1
SfJuyvApNifvd+zzCSmF/a499DLLP3Dst0NVpy4HIeQO1dBhnt863+5WqUtECPnEqtluzES/VZ69
PCHZsB8ORwfTf7PpGnbyhCRn5Wz6G+uOfTwh6ah3nTDoDvbxLVfpCEnAqVOPaforR3bxhMRl33qE
5AI5NBSekDistlHH7xSekDSshoS9OoUnJBZ1Y5c8g3PsmGRLiA/7Lspam4R1y4V4Qqyph+ST9XEO
u9RVQ8iiyLBbf2fdcgZPiBFZzdZH6Zh2QwhOtkP4W3oO6AnBWLUZD+FvWTepK4uQgll1qRWW+T5w
Ak+IisJcP3NkwI4QOQW6Tt8JUVCo6/SdECF1ua6fYbyOkEDqcuLwk75zPY6QAJriXT/Tc8cMITNU
mWxoxem4YYaQO6xSnD7lxbFNXZ2EZMsCJuy3cPpOyDhVEQnxMnoO5wn5xKIG8e9wOE/IR4aFDeLf
2TA6T8gVq+x3sCNsmVxHyBttah+dWbN7T8DXb398f+XPbz9SPw25sF/MEvs07N6j8uOv//z9fMvP
f/74mvqxyIJn7Newe4/Gjz8/mv7b+F/fUj/cY7PsGfs1DM5H4duv53v89zvH9MnYPUTHfmHDtXd3
vv39PMdP+p6Gwne2SjlyJ6wvX+ddp++peITw3C0dg3V+/PhPkOsv4/m/Uj/sw7GMra0yNjx43otv
/w2W/cQ/7N5j8mCj+Dc4mnfiu8T1c/f+NfUTPxCPN4p/g6N5B36Ezdhv+DP1Qz8MjxSL/whj8+b8
+J9c9ufn76kf+0FYeqrsfY7MtLHl60+N7M/Pv1I/+CNQL3Nzq4Ah9StYFFrZqXsEVg87ZX+nS/0S
FsQPUTCeukdl/8BT9nc2jNUZoZuzv8FQnStNas8yYc2Vdxv+QWR/fuY+GUcyiM+t+xPpBxhH6m7B
H5jszz+ZZuNGkpSaTd+17a6qPutVV1XVtO2hT3IAJhNtcL6Csj8//526CEuljry99WT5LnS1a3XS
vo8cP6TuMNCk/cIfqcuwTOp4Mq0PbaVKYtmfnI83ymdoHkSaL8uxfCxirbz1bQUGvFfNNtKzUneI
f9Ur7ddwGc6eGCtvx8NgFfuqd1GMZ9Y8wi9c9TOMy1vjL/umtc5IrXede/iOC+96/rWRnYE6a7xl
PzROe032g3MXT93VGHXtz89fU5dkWbjKfux2rsasfIWn7kqsunbO3G3xlP0QYx3LVXjqrsMiIP8K
w/J2+Mm+aaKZstq6zeGpuwpgN8xHuOZuhpfsx23kcyF2Xlt1qbuCb3ayP/8vdWEWg5PsmxSZaKvW
p4On7nLCj5gN4N/UpVkIPrJ3yfaUNC7pv9RdjOFAnkN5IzxkP7ZJj3arPAb01F2IXUT+zD+pi7MI
HGQ/tsnFWDns5TukLlRh/Glq+8/UxVkCtbns6zw2jjn4zpx5EabTdibYGGC+6y0T18/Y+07dJShO
kL8H74pCsZY9gzH8Nea+8yhaAbay83B5GFsbMnP9jPXt8/mMXPLH2HYmz4LYyt5lecVKZTt84b0S
oVjm1pzhPjgM09Nl+2w9ML2qlkdThkLbs2JnKUHOQ9x6a1jStXy2Evmwvw/Pez7At23bpor8Oba2
nUtwCJYL7dvsJuwfymoonDzLJqntN6z77qR9nDq3tv05cptZFIYL7ZtsB/HvDHbFFa/D5WP7K8d+
O7i/M/QcedpuiF3wqk1dlCBWdtm00gJnZ/uFTWd2RuAY7NvzwSwcvykmbGV3Jf1O9sOZ2v5Cb35W
4Buct2eDWTi+jI79gtlF1cLAfM62nzkMHmunjMnnwt6onZTTsV+wmr3LInW5235ivTV/k7Q9E6wi
dLmH4j9jdUGGaD9cAbY/nY8KtRX+h7Ht3PKqxKb5HYXT1zwwWnuXZMyXYfvTuYe3HNIb2848eR02
Db7PMlF2nspmYCPoCIux/cn01FDugcsBmxy6bepiqLEZzQty6kqy/TyiN/qMm10dceFr6nZTJCaT
9jJH8W+YDG7Cp+5l2f5kteeBZ9dkgEXbKy0W/xGTjTLBU/fibLc5lITn0qWnNWgLh+Ji8R8x2SQQ
+skr0HYT33nmbGosVtrLnbK/Y3FqT+iqe5G2G/huOnHnefJyaoO7FXLe3CqoCYPc4cDPXqG2w77/
ZSg774pRgMenjgVseItVF4En2RRrOxqv4z1wScEX35Z0dgu+WeAYNJYv2Panpw6I0RieMc2BvBh8
8W1ZF6bgofmgZbiibX866g/a5f3tKYH3gHnKvqqqoW3b/p2D+wFLeGg+JO+gbNuR9VazON03x0aw
UOBxvI/sq6rt+rvRw03fDpXLb8O6h4zlS7ddv6/ZqnPn/jcx8Dje/qqUfbMNV+HYtztz5WHdA8by
5duu7t6NOnd27WLQGLSx7Pu2V5i27hrb3Tiw7vMzjQXYrp2923TuzKMTU4Hv21L2VXMAJFtvLbP0
Ud3nt8cswXZtAuV3A9l/MiAvBsyrsZN93xrksR3sdmaius/m2CzD9qe1ZjT/w2DNnWvtYsD8eCvZ
V61BNt8FM+FR3ecsWIjtuitC8AOrGKITs8JetOhkpknqxvhO2c5mSA/q3s/8+aXYrtshgY7lOY6X
g7U4k6W3fWd5Idsra5N7ZUHdZ/q85diuyqwDz7DhmTVisKV2C9mtu/WrFmiQzIvpPrPoviDbNU3h
x/84aY8KtvUNl722m62P0eMDeixn/v4Id0m2axrDvz/1sjNlVg4UooM3wtStwxD+FvzsBUz3u1W0
KNs1iTZf1bpTdjkrxDZU9giun4F9h76IdwN1y7Jd0yC0ulN2BdDBDZhFkVw/swb3z0C1dO+3F2a7
SnfVsjtlVwCdTqXf73imcZ2vf6THhiFIHHF95+8uzfZYobo/LRr/w4G0NiirpnKLw08+LhJQhGKZ
d4ZAi7NdFbeVHm3xk1thNCAJ8hvgdy1OfhMDnL2ArcPdWYVbnu0q3f8STd7/+WEmwEMBdLBhJzGN
Y3WfqrghAsN5JDA/vQd8gbarxnw/wve//mROjQ6kBevFWSVs4sDVs8Cu4Okv4xJt1x0z/i1w9v4f
duxKgMmoflScqmO/AETnATNbh7+ZMbq1mj8DgvO/mBmvBeja1RG6lB37Be3RStABP1PHbCSvDRe0
eRjf/rk/hv9O1/Xou3Z1wuwuacf++vDaE26AmObU13GZtuvbx79/TA3of/7ifB0B6NqV3+4kofjP
qDZjnwFy6ia+MAu1HdkG/e+fvz4N6f/+zjU3EH3Xrpy076OvsU+hnYjo5eys/2DmgMnK3/76/v3X
3ye+f//+jeN3HH3Xrvxw5zCKf0M5mgd2FYz/4GJtP9oeCEpA1F27cqXd4r5ow9aoi83rDwMYDw4u
1vbZU3tITPRdu2rHeA1fRmONbqypLsb4N3K5toO7KIgp6q5dNee1uBPdGlUSiH4ZbrRzX7DtHMvn
g3o1af6M9BHwS9U8UO2TUVfcaOe+YNs5ls8HdTPTjOPzlF25LKwey49NHZZsu27KR+xR72vXxOPx
25G90OiuHsuP7XNftO1rcd0SF7RpLpp4PHasmy8a3dVx+ZG+btG265OUiSW19v0pAtk5y67TXato
b/enJv9gIFVVNe22d46dIruiiRnatW9F88pbdlXUUZ1j8zlKncr297JU7cHvyDB27jmgfb/y/Pjc
ZVf17tqP5efFy+S2v7BqOh/j2blngFZB+Qp1/rKrdFe68bnx52H7mf3gMaxn554eZRuTf6nRi+Hj
INddG6j7FPbIx/YTq615D8/OPTnaS13FIbpc19k/Itddaena6O+8Yp+/srNeJGDnnhrlCWvi5dNS
ZFfors1X+Bj4yM3203DM1vejwyMSCUoJpbvGcsyNn0KsuzJh4WOcLj/brX2Hb+EjEMrImbhpFSS7
XHflKtzHeWyOttsmPyLXDhTHqtq1bdvf0rXtUIFXk+lRJnpLnzePU6nCW6VQd+Uq3IeuLk/bTfcn
o9cAF0FdDdt+JsbZH9omemUoY3TSja4lrL3dINRdmS7/QdBMbbfs3qELxEpAlp/Ub5uYW4EH3UsT
PiJ0nWQahLorO/fbeszWdrtDBO/E6ZpeT1Duh/qvh0YbqlbzBtddE2tlUvcWhV9o5Oz1ZMh0Vxbx
9kiXfG23G81Pb3xFDi8LKrv6r4csHNbNAWjlmyFGF68cyAsfrcy9XTLddW11bVhPzudFGEVepjuK
gm0/qQ5XTAThdYvtwq49rxMnBfUv0V3Zud8EarK23Uj36aF8sbZXViHog/OJH7rkSNlHqIyE2TFE
uusa682EM2/bjXSfbNFl2l4PlgnG69ZxCq+LnsmaVe23hdIdie66zv1mKJ+57Ta6Tw4MS7TdrFv/
zbFzG9DrBvKytfbCVtpvkeiuK+j1UD53201SpCaH8uXZ3vgEpLx8V3W7slalv2EhCwS66yKe10P5
7G03WVyZyikpzfbG8dwPj/G8biAvynUucvHtGoHuqrjs9VA+e9tNYjBTF0mUZfvOdYJ6dLhtQ1W/
ss1vyh12GRGuu06Fq64uf9st3ufUQcUl2W68N3CEjXkuvWoaJtqjXG48/qreg3VX1efVV7wA2w1i
rlMT93Jsj3MX+dZ2OK+baIqeoeB4/DvBuqt2A1xtCyvAdovv98TEvRjbh0iz07Vp965qnKLMmlLz
aj4QqrsuSPH+x0uw3SAxcmJOWojtq4iZoarLCSdQRZUk35viQ3RvhOquGuG9Rz2LsB3v3Cc6jDJs
j9WxX9jYLcZpfl4Uo3Ob3vRdu/t9JEBdVUN78D0tI1B31SLHe9SqCNvxzn3iSIsSbI9+FfnRKplW
9ZGWrAy47HNdd1OHAFQDsgtphkDdNd+c96hVGbbjGRTjf7cA21OcrWi0FqdaTJHE6OwnOIe5fUJ7
+2ORXwnTXRUK+f31KsN2PPQ6PhvM3/Y0Z7LYHACi6Yckl7par75thiDh9lufD3CQ7qor9X53HYXY
DsdeG/M/G8X2VDngqqvGLRqmJI/OtmvvBId4+eQvB1W6Zl73u6kWYrv2BoLfjKdsZG57ne6UBgPd
NdMvyXngll37sRXGJl1ynUIqXTWpffvHhdgOb44ZHyHmbXvSE9Jx3TXjEskUwtA3zSYBD99DKl0z
jXibx5ZiOzqU783/qrvtZgfz6YB11zy+YDnArms/KNccK/uAXUClaz6ibyPbUmyHF1tG/2rOtie/
6AjUXTNtlwzkrbpWJH2wNX9H85WuGcq/tdVSbNd788r42/Iuu/qvt8llR3XXtErBQN6qa8e2Bthn
Oc5XuqZhvP7TYmxHq3X0C56v7U6rPDIg3TWr7YKBvM1qBb4vwDxTf7bSNSWvLCSKaDtaq4XZngfI
+9W0LO9zXD5yMFho3FvP3ud016RgDPp38k5E29F0utFug7bPAKTZKH5NkFpj0qXa3PZtvk46o7sm
IPJas8XYjobpRt8sbZ9DfUOuZl4tyNg1mOgczW7/tc6BmtFd4exa/S+viGg7Kg5t16G9JnJQ/Fb4
SphBQvHR8AJMTWHvcV93fdWWYzv4NaftOtbKqa2ivxNsdsUHz5ay229nuKu7Zpi7M6i2mLaDL3h0
Bkrb55HsU7lCkVsTfpAGHqOzlT2y7oqOrzVwqCDbRx+Vtgeg2/+q+KHw9bcBLZO17HF1V4ybegOH
YtoOHupA27UcNYmlmiBd+JwBXfSylz3q3F3xZblkKZZjO7jmQtt9S4q3/U3wH4fTqM2P0j4TLzKv
mci8/DHa7lp27JlzQXF0lSKTLnzajt4wYLb0dku8dXfF2KbCn5C2z4A9cy4o4vKKdhX+TQEH8jYn
83zG/LLZSd0Vs9pW+VauoO0zYM+cDfKkM0XYOPiTAg7kw2cMUsx3Mk3pPsj/1Msnjra7lh175nyQ
BuoU2Z3hq+0DVBRV0DHKk40wobsiBvrSXGm7a9mxZ84H6eBX0RzD1/WxUz6cJu0XzE8Dn9Bd8ZfO
/4y2u5Yde+aMEHaIg/wXgpf1Vadb/kaZLBT6bJGOt1BoW+v+2RUxbQcDsbQdQti5Kyo2eFUMymPx
HMefGcxrflR3hQzn+i3HdubSJUVmieJlBf9taF3bZpOrXzMdYUx3xRevgR+uINtHR3C0PRhZ5y6f
WocH6ZB1LtEtcyocbqsa0V0RF2lhh2LaDi5mcg8ciGjNXf7ng+fT0I4Yqyvu7uBw4ceI7roKLsd2
sMZoO4hkDKwIpAX/eWTaHqO92pyhdctn3eWDp3PZi7EdrUTaDiIZBCsGmsG9LhKtdcmP/4jHbV6f
dJcv9Z33xRRjO3qi8OgyK20XIBgFKw4RDN6VBqy2x2muHp37J90VLfdLQbbzzNnU+J4RGfy3gRK4
Jta843JV5wfdFfOZfUG2o1lKtB0mPE4nr9fgeQIwxvMPyMOPeIdb3RW/URVkO5qkNNpUabuE8ENs
5K0quCUN+ud3X2t/w+cCvxvdFXHQXTm2P9o9cAKO/aFth+rC0LYHr3ugwzePyZ8geDkfCNI5p9G9
Y31q1Vv9X+su/+dtObYPaE2N/tXSbV93w+gMZbVrPZQP1kWeGxHc7+rL5Zshfw2WyT/Nte7yeijI
drTxlnajcwCH5q599a6z3qIRPJSX/+ngAJq+TJFidGfMt8K9cqW7avhUiO3w13K87yjX9r4JiJnV
je0MMrh3lP/p0JVwoCEY3PkWitNQ/lp3+YymL8Z2uPrGu6VCbT92wWPqynREHyiMYsU5dLldH++O
mffpNZS/0l3edMuxHe6jxvuOIm0/tqJeqjLs3wMTbBRKhpZGf/mn7lh8JT5R+TNvusv7v3UptuMb
i8YFKdH2TjwitbtiPPBYWEfb9WVxOEI+RpV/4lV3VR2XYTucnTSRWFGe7RtNo91bdTWB+Snylhi8
uKdegDuaN0rbGhDUVa38hUJsxzOPJ+JLxdmuTBCprZI5w+IFg09lnlG315jTds+J+9Or7gonCrEd
b6sTlhRm+1o/GjUaWoZN3FURpDDU7TVaIt0Fv4n706vu8n9WF2G7wXEgEws8ZdneI2tINotCYRN3
ebUGr+2pl9sjnGNxjdeK+4Wz7vJ/VRVhu8Ei0sRfLsp28IoTE93DXri8WoN7XvWTRw3SuYbpzpx0
l/+jImwf8MqZes6SbIfvMzJpgE6/5G+7RUsUoF8pDGNTy+cKJdi+Msj/nGpMBdluMO+0CNUFdZF+
tqujX7F2u77hGZR/YbNM2y3iHVN5meXYbnFToaI7+ERQsrlqx0YQaonihuSdg/JKCrDdokOaXGst
xnaba0kNhklBWtL2LE82yN92k9jSZMS3FNutNmsO8JMElVreqEIj5mrbIy/A0XYNNutGk8PPQmzf
mG3fgpc3gua/8l8J3QJXju2uC+46hsxtN7oQe1KWMmw/2q0e4akLIb9C2z1uiIJp87a9sZF9ehxc
hu2Wu7fgMEiIl7SdtouxOhNgOo5chO2mlQxvOaDtYdB2GeAVzu9Mz3qLsN328ES0cw/RhrbTdhkr
s+q6s3pVgu3G7RRN+6DtYdB2ATu7ExTvtKQCbD9aH6cGBotDik3b4SuJPWgytd2uY7+/ZlSA7ebN
dMCex8f20PNg1bYHnrpjB/7mzclzvb023UB0L6Kdv+3mXTua0xmy4M5cOtoeSN2aHoN+V5f8bXcY
gYJbr11+YXG2u1z0CpKf7SvrKw/uZpjnb7vDIegD9kQBv5Dhjte4x9JF2AOnIDPb68Y+knl3/Sp7
2212w9wCdjsBC4IZ2h57f7vb/REAOdleNx6n+9zXJXvbQ2PVIrBwccAj5Wi7S01aVoE/udhe77ZO
uwju90S52+5zBgOWYONie3BDUn+pIt4CdybD5fYcbF9Vbee3X2hmJJy77T7rRtgoM63t6vYaeQnO
+sJNC9KdMF1VVdO2vfcncGaSmbvtPkcnYhvhAmyXf06Wdp58jiF52PbcmQtyZW67VyAZeqiAHXny
gHTwlEX/xpzqchzvQyiVNbBo249z8ePMbfeIyJ+BZk4BAbUs74GLGqYz29FlycJtn22YmdvuFVmC
Fj/S2q5fx446cc8wS/58196SbV/PpqZkbrvtXlebYofYrkjOdd8WE36xpAFZTtvh+9vzZv5ow7xt
d8v/goLyIUvj8r8aPM7WP7jXt3OEAXrvTizb9oCDWvO23S2MDKV1+tgefBqXfoxseeDXDBkeQfky
lVmu7SG7x/K23W1PNrQEF/JU8tYeXFZ9g403lM9yIH+u4uXaHnJEed62+11LijyVz+E1wcsPwCuL
dvFjjmmzy7Y96MKFvG33a53IU4XYLg/6B89agJiD14LmJ3KMyL+ERpZq+3w8/kzetvs1R+SpQmyX
12twRBKYhdifDDJOjvvfnhZte1i/mLXtjteSIm/dx/bwTxvw6JHidJk6VWf7ZCiB7zVr2x0zu71t
VwT9g5fggGePc61zjgdZnAHfe76EztCytt1xmpmh7cF5g8g7i7LtNVOl1vk+GkbwLYlZ2+54KLK3
7YpkuuDSIhtOYnTuuXbtPfjecyU8GkPbnR7LqTrPQGfmRjhWPlejuoyfDUBwJWrWtvstt/vbLv+B
8DRhJFHNPyyf5V7Xt/e2QNsFWxuztt1xi6a77YpddsEeQrtJvdfc6zzX2p8uncfybJdEYmi7nCDb
FRUbXFys93Te5g59io6eK/UV+N6zRBR2pe1ygmxXNNvwKTVSqc6BOuwQsM4zMQd97zkiW2Oh7XKC
rFQEpsPTC7CTyD1PtaixzW+NYx7eGn3vGSJcUM3adsf92O62KwoeHqYbkFp1/YqCB1SdQxdeuvfo
e88PafZE1rb7NcoItiuCVcFfN3BDqV9cHjS1t/gjd1/bkmw/itesaLucMNsVvxD+qQYPi/Da6L4H
D5F/Tff20b1B33tmCNbZ36DtcsJsV9Rs+OLYgFSr6JckwItvb4MbF90r9L3nxUYxz6XtcsJsV7TY
8Gg5fDaMx2Y4MEJ3PeTw0B1+71lx0MzGaLucMNs1S1Hhn2v43DeH7THY7Xq3z2Sv+wZ+7zmhS4Cm
7W41rfjL4QriNpjrDst+k0xorvsBfu/5II/PXaDtcgJtV/xE+HQa2hlzwVh3XPbbo9WsdW/h954N
min7C7RdTqDtiqVnwfn5uFy2uhs8z4cOy1j3Cn7vuaDfxkjb3Wpb01rDF1UsNpHb6V5j2X0vfIpR
2uq+FNvXwNGstF1OoO2aMJ3gs22x1cxqIQ6Oxo+X3VT3hdjeIplRtF1R4YG/oUg1EaS9mKjQm2TV
oUk1F0YexVL3Rdi+wc5cp+1yQm3X/EZ4/KU2UQwZF74xWDzI+DjDUHf8vSfniGZJ0HY5obZr6lYw
lTa6IR09uqo2Emj8s2OnO/7eU9PBAzHaLifUD00gLeiCnwtWd61ho8OdyRBjujGZ6Y6/97T0BjtC
abuc4N5Q88cF32+DRa/XAqk7jZWZPZO7cI107/H3npLeZJcybZfjehurYChvd5HqWrcWV9td7ngn
PGmj+wF/7+mwcZ22awi2XTOzFgzl7Tp3VXuqW6NB/Jl7nxsT3Vv8vafCynXariHYdlUGjGBUbXpL
ei/r301dn9n9Z6F7hb/3NHSGFx3Tdjm+p0VKVlkMO/cT6yH4S7PvLF2fPTsL1/0tJ7kw29et6Wlt
tF1OuO2aX5GcK2PauZ85hGyuWg0WqXPXzLYkWPe3CVJRtge9DQm0XU647YPmz0u+5rad+5njobn7
AHtz1Z9CjsVEdX/zphzbN+EjrWBou5xw21WHq0sOgLZJqPvIuhvG/Kur9uDyeyGRSUz335sLC7F9
M7ict0zb5QiyzzRyCLa9Yu/vPut+27bVhV3bHnoX0V8IatmQ7r9fWQm29z6qg62Fts+iGmmLguPZ
3roWTuBgBtH9tz65277udo7XctJ2OQLbVTe2hd8Zo/2FrAg+3V6v+/uOm5xtX3eN430pZ2i7HIHt
ugOlRO885/YbRHjgWav71fck09o69q1nn/4GbZcj2TWmOtNFdMjEym8+HQXJSEap+1UKQ362911b
OXfpv6HtciS2D5ofkN3cpPqJbDiKWrpK9+t2mo/t6/7QDtE8v0Db5Uhs1+W/yE4tcFgAj4fwgAaF
7jffk6S2b/oTbds2VRVh2D4CbZcjOv9BpaLsinXsyvS0iCKSZ+S638QFMNvFT5sZtF2OyPZB9ROy
HSq638gB2Tj+Banut4MH2u5adqRyHcsdzXbdUF7WuWc0GxWiOWhNpntnWVO0fQakch3LHc125axa
tqW51Li8ZDP/OxLdPy5v0HbXsiOV61jueLYPMdpVmTk2a2WsKlz3T6+KtruWHalcx3LHs115Y5vw
vBKjA2jjoj6TJVD34+fwB213LTtSuY7ljme7LsFG3LAKXIYDTkcP0n3sMF3a7lp2pHIdyx3RdmUC
mLDj89n76olu0v5WqbPFPY6+JtruWnakch3LHdF2pYfSllXaqvsGSzCpZ7YXduNre7TdtexI5TqW
O6Lt2gNmpKc+W1947otipf0Dqztn43VTf522u5YdqVzHcse0XXn5snDN3ePYKkcsTlKtm9GzdDbN
9LCBtruWHalcx3LHtF174oQ4imVwiXoszK6Or9rDVYDy2G/v7xyl7a5lRyrXsdxRbR90vyPbCvfF
6Br1KKBXTX7k9UQt3/dO22dBKtex3FFtVy65K36oEN1FG/izee+0fRakch3LHdV29YxaHMkqI4U2
ney03bfsSOU6ljuu7co4naJ17QvQPaHstN237EjlOpY7ru3qTDf5ZSH56w4utKd777R9FqRyHcsd
2XbtWrhi30juuieVnbb7lh2pXMdyR7ZddY2E8rfy1r1PKjtt9y07UrmO5Y5tu7qeFVkoOeuecs6O
vnfaPgtSuY7ljm27+jZWyZWvb+yzXYhLLTtt9y07UrmO5Y5tuz6tVfNrua67J5edtvuWHalcx3JH
t13duau2j9RZnlRnli6b5r3T9lmQynUsd3Tb9b+oGcvnuEXmmIHstN237EjlOpY7vu3aDBvt7w1I
zTtwtNj1lvS90/ZZkMp1LHd824Gf1HlSZRWaT7vMbvLeafssSOU6ljuB7frOXWnKKqNYXfr4HP7e
afssSOU6ljuB7cBvKl2ZO80pGllM2eH3TttnQSrXsdwpbNd37op8+QvzhzXGYJPFlB1+77R9FqRy
HcudwnbgR9WnuOUwmt/mMWWH3zttnwWpXMdyJ7Ed6Nz1QS7kDVtwVN8Rkdt7p+2zIJXrWO4ktiO/
qg9zpc2jPWTUsYPvnbbPglSuY7nT2A507kgiWrrufa0NOOT43mn7LEjlOpY7je3QqbBAqGufKJE2
pxk7/t5p+yxI5TqWO5Ht6mz5J8URtNekCM73+YTif0PbXcuOVK5juRPZDqWvQ+lodexbYPNZY7+G
truWHalcx3Knsh26nBFLSFvFvFzi2OKD+P3Qvb6mdd/ubCYFtN217EjlOpY7le1YxGyLFbqKNn3v
YDdX24837BwsBgu03bXsSOU6ljuZ7bXymqgLaIOP43sH3+g4/pwGAwba7lp2pHIdy53M9i87qL3B
K1r+vuOuT8854GAAbXctO1K5juVOZzvW4Ax2iVeee2Us5uvDvdgGeGYtbXctO1K5juVOaPseanAW
h0Kstk7rcesGd31u4x5WAbTdtexI5TqWO6HtX7C1MJszYBqHAX1nkREfcIAmMpqn7a5lRyrXsdwp
bYdW4cxOgfkc9MaeyqBb/xJ4Wi6gO213LTtSuY7lTmk7GKizO/Sp6oxG9JsBjsxdCDwaWx+rpO2u
ZUcq17HcSW0H25zlGW8V3sP3Vqp/CU411M9maLtr2ZHKdSx3WtvRa9ZNj3RcDQf146w7mwH8K4N7
BdB217IjletY7rS2w8c/W5/gWimM33SNXaf+guAmXG1WIW13LTtSuY7lTmy7+kL3NzbGop1Y7do+
cFi/ObSV/WZW0bXXyrE8bXctO1K5juVObTu26P7kdxlD1bTdtPR9v20rp1+W3XGvFI+2u5YdqVzH
cqe2HT9PxvnulX1VVe07w+n/2g8nrhmE5dct7tN217IjletY7uS2w2P5zI53RBGn8+q2/9J217Ij
letY7vS27/G17izPi9ChyN1XBQ5ou2vZkcp1LHd62y2uZTR6kuSo7ptXfetou2vZkcp1LHcGtqM5
NmdyuV4NQ3cG9iF+ndP2GZDKdSx3DraD+fIvZHJ1KoTyMtqj5rdou2vZkcp1LHcOtqP58i+sMzzY
VcagLbqm5LTdtexI5TqWOwvbwb2vF/I82zUY4B5azd4Y2u5adqRyHcudh+34MtwZ8GzKpCC3Umre
A213LTtSuY7lzsR2g2W4J5c02kjskPLTdim0PU4rm8Ji6n4azed23Vog2EyGtkuh7XFa2SRGV7iU
OJpH75an7VJoe5xWNo3Rfcub4mLz0Che+x5ou2vZkcp1LHc+tlusup85Do7VZU+N31NF26XQ9jit
7A7w5tff76OgYJ0yo+YGzeeNtruWHalcx3JnZLtwY/cdiunebS6c1ewBpO2uZUcq17HcOdkOXfP8
4ZUU0b3vbM621iQN03bXsiOV61jurGy3itQ9nW9mcqw0G6xull5rfpy2u5YdqVzHcudlu2rH5wSb
zA+5GKxupVLt/6PtrmVHKtex3HnZbpRT9wp+ebofld13TZVRRNtdy45UrmO5M7PdLjB/Jtvh/Mrw
elnVQJ62+5YdqVzHcudmu11g/oV1jqm0dWs5gtG9BdruWnakch3LnZ3tFgdX3byc7Kbvjek10kfd
dIW2u5YdqVzHcudnu+E63OvrySqXtjG9UVb9Emi7a9mRynUsd4a2m+v+1GWz+m7turZrfzkmX09W
308FK++yI5XrWG7krbs5ZHAs5Uffs2if5q4jdzoTkgWWy+5vpJ+/O7i+kLN2yUPjoftTn/LgOts4
/Bub1C+KEBwX3Z/WQ6J8m715KOIie8bpQ4QE46N7mgl8Yx+HoOxkSXjp/rSJ28Gvth5DeMpOFoWb
7qcOPlYkux4cC0HZyXJw1P3pGGFEXzdGW1rHZU/9egixxFP3p6f11rOH91WdspPF4av7uYdvXIbD
q8EpLkfZyXLx1v1E39qO6evd1iGLhrKT5RNB91MXfxhsEu1Opsd43qey77ckZAqfvJTP9NsdlPe/
H7ooplN2smBi6X7i2LeNvJevq6HznqdfPyRlJ8sF2YysYd1v210VEL5bVU0b0/OL7Fns5iPECdvD
qwTWn7Rvh6tdzZf/uWvbk+S9V4rczENRdrJsLK5PWgbMliWLZ++/qFUEzJYlD0CUlbjsKfFyekLk
RAzNZwqD8eRhGFLbllp2xufI4/DYsTrG58hDsXrgyTsz48mDUT/q5J1TdvKA2N6qVAobTtnJI7J/
wNE8V9nJg/Jwo3mO4skD81ijeY7iyUPzSLF5ps+RRyf2JthUrJNfXkdIch4jWHdgeI6QL1/qbWoV
3TnytmZCLlQL3wXLjp2Qd5Y8e1+zYyfkmuXO3rfs2An5wLDItfcNQ/GEfKb2vW4tBcchdaUSkinV
wobzHXSJBSHLZknDeQ7iCbnLYhbfuQOGkFlWsa9scXG9ZSSekACq4n3nhJ2QUHZFJ9fRdUIkNMX6
3jM4R4iQMn2n64RoKM93uk6IlqaodBvO1wlBKCY+f6TrhKDsSziZluvrhJiwajPPp90wb44QM5qM
B/QdD44mxJR9l2UHvx44hCfEnuw6+GPHFTdCnFi1GS3BHxp264R4st9mIfxm4IIbIf7sUk/hqToh
8UjYw1N1QmKzH+Kn1R45VyckDXXTReziN1tG4AlJyX44RJjFrzt26oTkwMl4xz5+s204UyckI1a7
1j75Zn1oK/bphOTI/qS80bh+Q9EJyZ66GtoeiNf3h3bHjS6EFMSqatpOYP2x77ftjv05IQWzqk7e
tyfzz2yu7T6zPf2nXVWw5P8HgU8L06qlZUcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDMtMTZU
MDg6NTI6MTMrMDM6MDCV+5GDAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTAzLTE2VDA4OjUyOjEz
KzAzOjAw5KYpPwAAAABJRU5ErkJggg==" />

			{/* <path d="M627.11 127.29C627.11 197.46 570.225 254.344 500.055 254.344C429.884 254.344 373 197.46 373 127.29C373 57.1192 429.884 0.234863 500.055 0.234863C570.225 0.234863 627.11 57.1192 627.11 127.29Z" fill="#5571D2"/>
			<path d="M596.012 127.29C596.012 180.286 553.05 223.247 500.055 223.247C447.059 223.247 404.097 180.286 404.097 127.29C404.097 74.2939 447.059 31.3323 500.055 31.3323C553.05 31.3323 596.012 74.2939 596.012 127.29Z" fill="white"/>
			<path d="M537.655 164.798L562.611 139.842L633.063 210.294L608.107 235.25L537.655 164.798Z" fill="white"/>
			<path d="M570.641 127.29C570.641 166.273 539.038 197.876 500.055 197.876C461.071 197.876 429.469 166.273 429.469 127.29C429.469 88.306 461.071 56.7036 500.055 56.7036C539.038 56.7036 570.641 88.306 570.641 127.29Z" fill="#5571D2"/>
			<path d="M531.818 127.29C531.818 144.832 517.597 159.053 500.055 159.053C482.512 159.053 468.291 144.832 468.291 127.29C468.291 109.747 482.512 95.5259 500.055 95.5259C517.597 95.5259 531.818 109.747 531.818 127.29Z" fill="#5571D2"/>
			<path d="M535.348 127.29C535.348 146.781 519.546 162.583 500.055 162.583C480.563 162.583 464.762 146.781 464.762 127.29C464.762 107.798 480.563 91.9966 500.055 91.9966C519.546 91.9966 535.348 107.798 535.348 127.29Z" fill="white"/>
			<path d="M522.995 100.382L547.951 75.4262L653 180.475L628.044 205.431L522.995 100.382Z" fill="white"/>
			<path d="M522.995 100.382L547.951 75.4262L618.403 145.878L593.447 170.834L522.995 100.382Z" fill="#5571D2"/>
			<path d="M596.012 204.7C596.012 213.41 588.951 220.471 580.241 220.471C571.532 220.471 564.471 213.41 564.471 204.7C564.471 195.99 571.532 188.929 580.241 188.929C588.951 188.929 596.012 195.99 596.012 204.7Z" fill="#5571D2"/>
			<path d="M624.453 154.444C624.453 165.24 615.702 173.991 604.906 173.991C594.111 173.991 585.359 165.24 585.359 154.444C585.359 143.649 594.111 134.897 604.906 134.897C615.702 134.897 624.453 143.649 624.453 154.444Z" fill="#5571D2"/>
			<path d="M72.8587 45.2897H0.138672V213.29H39.0187V166.97H72.8587C117.739 166.97 145.819 143.69 145.819 106.25C145.819 68.5697 117.739 45.2897 72.8587 45.2897ZM70.6987 135.29H39.0187V76.9697H70.6987C94.4587 76.9697 106.459 87.7697 106.459 106.25C106.459 124.49 94.4587 135.29 70.6987 135.29Z" fill="#5571D2"/>
			<path d="M177.656 66.1697C191.576 66.1697 200.936 57.0497 200.936 44.5697C200.936 33.0497 191.576 24.4097 177.656 24.4097C163.736 24.4097 154.376 33.5297 154.376 45.2897C154.376 57.0497 163.736 66.1697 177.656 66.1697ZM158.936 213.29H196.376V84.1697H158.936V213.29Z" fill="#5571D2"/>
			<path d="M297.604 82.2497C280.084 82.2497 264.964 88.2497 254.884 99.2897V84.1697H219.124V213.29H256.564V149.45C256.564 125.69 269.524 114.65 287.524 114.65C304.084 114.65 313.684 124.25 313.684 145.13V213.29H351.124V139.37C351.124 100.01 328.084 82.2497 297.604 82.2497Z" fill="#5571D2"/>
			<path d="M204 46.8198C204 60.6269 192.807 71.8198 179 71.8198C165.193 71.8198 154 60.6269 154 46.8198C154 33.0127 165.193 21.8198 179 21.8198C192.807 21.8198 204 33.0127 204 46.8198Z" fill="#FE637C"/> */}
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 1005,
	height: 408,
};

export default Logo;
