import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';


const Brand = ({ asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus }) => {
	return (
		<div className='brand'>
			<div className='brand-logo'>
				{/* <h1 className='brand-title aside-logo'>
					<Link to='/' aria-label='LogoAside' alt='PINAT'></Link>
				</h1> */}
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => {
					setAsideStatus(!asideStatus);
					setAsideSubStatus(!asideSubStatus);
				}}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
