import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Badge from '../../../components/bootstrap/Badge';
import { useChannelIOApi } from 'react-channel-plugin';
import { useMst } from '../../../models';
import NotificationList from './components/NotificationList';
import { observer } from 'mobx-react';
import NotificationSetting from './components/NotificationSetting';
import PaymentModal from './components/PaymentModal'
import moment from 'moment';
import 'moment/locale/ko';
import AlarmService from '../../../services/AlarmService';
import axios from 'axios';
import PaymentService from '../../../services/PaymentService';
import CompanyService from '../../../services/CompanyService';
// import moment from 'moment/min/moment-with-locales';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren, planList, checkPinatState, loading }) => {

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [ settingFlag, setSettingFlag ] = useState(false);
	const { fullScreenStatus, setFullScreenStatus, lang, setLang } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { showMessenger, updateUser } = useChannelIOApi();
	const [companyInfo, SetCompanyInfo] = useState({});
	
	const { i18n } = useTranslation();
	
	const { user, company, notifiction } = useMst();
	const { t } = useTranslation(['translation', 'menu']);
	const countingDay = 0;

	const getCompanyInfo = async () => {			
		await CompanyService.info({id:company.get.id}).then( async res => {
			// console.log('res >> ',res)
			if(res?.data) {
				SetCompanyInfo(res?.data);
			}
			// await company.setRestInfo(res.data);
		});						
	};

	useEffect(()=> {
		getCompanyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng).then(()=>{
			moment.locale(lng);		
			// moment.updateLocale(lng);
			// moment.defineLocale(lng);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`${getLangWithKey(lng)?.text}`}</span>
				</span>,
				t('You updated the language of the site.'),
			);
		}).then(()=>{
			setLang(lng);
		})
	};

	const allReadHandler = useCallback(async()=> {
		// console.log('userId, companyId')
		await AlarmService.alarmReadAll({userId : user.id, companyId : company.get.id}).then(response => {
			if(response.data){
				// console.log('response >>',response)
				showNotification('알람','알람을 모두 읽었습니다.','info');
				notifiction.getRefresh(company.get.id);
				setOffcanvasStatus(false);
			}else{
				showNotification('알람','모두읽기 실패.','danger')
			}
		})
	},[user, company, notifiction])

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
		moment.locale(i18n.language);		
		// setLang(i18n.language);
	});

	const { setIsOpen } = useTour();

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/* Tour Modal */}
				{/* {localStorage.getItem('tourModalStarted') === 'shown' && (
					<div className='col-auto position-relative'>
						<Popovers trigger='hover' desc='Start the "Facit" tour'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='Tour'
								onClick={() => setIsOpen(true)}
								aria-label='Start the "Facit" tour'
							/>
						</Popovers>
						<Icon
							icon='Circle'
							className={classNames(
								'position-absolute start-65',
								'text-danger',
								'animate__animated animate__heartBeat animate__infinite animate__slower',
							)}
						/>
					</div>
				)} */}

				{/* 결제 */}
				<div className='col-auto'>
					{!(checkPinatState?.state === 'ACTIVE')&&
					<div className={(checkPinatState?.state.includes('TRIAL') || checkPinatState?.state == 'CANCEL_PENDING') && 'mt-2'}>
						{(checkPinatState?.state === 'TRIAL_PAY_PENDING' || checkPinatState?.state === 'TRIAL_PAY_COMPLETED' || checkPinatState?.state === 'CANCEL_PENDING') &&
						<span className={`mx-4`}>{t(`${checkPinatState?.type}이 ${checkPinatState?.countDay}일 남았습니다`) + '.'}</span>
						 } 
						{company.get?.isDoc && companyInfo?.ceo?.id === user.me?.id && checkPinatState?.state !== 'TRIAL_PAY_COMPLETED'&& 
						<ButtonGroup>
							<Button
								icon=''
								type={'button'}
								color={'info'}
								//isOutline={true}
								onClick={() => {
									// company.setData({pinatState: 'TERMINATED'})
									setIsModalOpen(true);
									// axios({
									// 	method: "post", // 요청 방식
									// 	url: "https://testpgapi.payletter.com/v1.0/payments/request", // 요청 주소
									// 	headers : {
									// 		Authorization : "PLKEY MTFBNTAzNTEwNDAxQUIyMjlCQzgwNTg1MkU4MkZENDA="
									// 	},
									// 	data: {
									// 		"pgcode" : "mobile",
									// 		"user_id":"test_user_id",
									// 		"user_name":"테스터",    
									// 		"service_name":"페이레터",    
									// 		"client_id":"pay_test",
									// 		"order_no":"1234567890",
									// 		"amount":1000,
									// 		"taxfree_amount": 100,
									// 		"tax_amount": 20,
									// 		"product_name":"테스트상품",    
									// 		"email_flag":"Y",
									// 		"email_addr":"payletter@payletter.com",
									// 		"autopay_flag":"N",    
									// 		"receipt_flag":"Y",
									// 		"custom_parameter":"this is custom parameter",    
									// 		"return_url":"https://testpg.payletter.com/result",
									// 		"callback_url":"https://testpg.payletter.com/callback",
									// 		"cancel_url":"https://testpg.payletter.com/cancel"
									// 	}// 제공 데이터(body)
									//   }).catch(function (err){
									// 	console.log(err);
									// 	// res.status(err.status || statusCode.INTERNAL_SERVER_ERROR);
									// 	// res.json(
									// 	// 	jsonResult.withoutData(process.env.FAIL_CODE, err.message)
									// 	// );
									//   }).then(function(response){
									// 	console.log(response);
									// 	// res.json(
									// 	// 	jsonResult.withData(
									// 	// 		process.env.SUCCESS_CODE,
									// 	// 		process.env.SUCCESS_CODE_MSG,
									// 	// 		response.data
									// 	// 	)
									// 	// );
									//   });
								}}>
								{t('Pinat Lite 구매하기')}
							</Button>
						</ButtonGroup>}
					</div>
					}
				</div>

				<div className='col-auto'>
					<Popovers trigger='hover' desc={t('채팅 문의')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={'SmartToy'}
							onClick={showMessenger}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>

						{/* 
						<a href='#' id='channelBtn'>
							<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							isDisable={true}
							icon={'SmartToy'}
							onClick={() => {}}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
							/>
						</a>
					 */}
					</Popovers>
				</div>
				{/* Dark Mode 개선 필요*/}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc={t('다크 / 화이트 모드')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>
					</Popovers>
				</div> */}

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc={t('전체화면')}>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={getLangWithKey(i18n.language)?.icon}
								aria-label='Change language'
								data-tour='lang-selector'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button isDisable={lang === LANG[i].lng} icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/* Quick Panel */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							// eslint-disable-next-line react/jsx-props-no-spreading
							<Button {...styledBtn} icon='Tune' aria-label='Quick menu' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
							<div className='row g-0'>
								<div
									className={classNames(
										'col-12',
										'p-4',
										'd-flex justify-content-center',
										'fw-bold fs-5',
										'text-info',
										'border-bottom border-info',
										{
											'bg-l25-info': !darkModeStatus,
											'bg-lo25-info': darkModeStatus,
										},
									)}>
									Quick Panel
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Public' size='3x' color='info' />
										<span>Dealers</span>
										<small className='text-muted'>Options</small>
									</div>
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Upcoming' size='3x' color='success' />
										<span>Inbox</span>
										<small className='text-muted'>Configuration</small>
									</div>
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Print' size='3x' color='danger' />
										<span>Print</span>
										<small className='text-muted'>Settings</small>
									</div>
								</div>
								<div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='ElectricalServices' size='3x' color='warning' />
										<span>Power</span>
										<small className='text-muted'>Mode</small>
									</div>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/*	Notifications */}
				{(checkPinatState?.state === 'ACTIVE' || checkPinatState?.state === 'TRIAL_PAY_PENDING' || checkPinatState?.state === 'TRIAL_PAY_COMPLETED' || checkPinatState?.state === 'CANCEL_PENDING') && <div className='col-auto position-relative'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
					{notifiction?.get.badge > 0 && (
						<Badge color={'danger'} className='position-absolute top-0 end-0'>
							{notifiction?.get.badge > 99 ? '99+' : notifiction?.get.badge}
						</Badge>
					)}
				</div>}
				{afterChildren}
			</div>

			<OffCanvas id='notificationCanvas' titleId='offcanvasExampleLabel' placement='end' isOpen={offcanvasStatus} setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle className='w-100 d-flex justify-content-between' id='offcanvasExampleLabel'>
						<div>
							{t('알림')} ({notifiction?.get.badge})			
						</div>
						<div>
							<Button color='info' size='sm' onClick={() => allReadHandler()}>
								{'모두 읽음'}
							</Button>										
							<Button
								color='light'
								icon={!settingFlag ? 'GearFill' : 'Bell'}
								isOutline={true}
								className='border-0'
								onClick={() => {
									setSettingFlag(!settingFlag);
								}}
							/>
						</div>
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>{settingFlag ? <NotificationSetting /> : <NotificationList />}</OffCanvasBody>
			</OffCanvas>
			<PaymentModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} checkPinatState={checkPinatState} />
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default observer(CommonHeaderRight);
