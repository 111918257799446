import React, { useState, useContext, useEffect } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Logo from '../../../components/Logo';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import classNames from 'classnames';
import { adminsMenu, demoPages, pawsswordFind,layoutMenu,extraMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey } from '../../../lang';
import { useTranslation } from 'react-i18next';
import { Configs } from '../../../models/Configs';
import Icon from '../../../components/icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import showNotification from '../../../components/extras/showNotification';
import AuthService from '../../../services/AuthService';
import BoardService from '../../../services/BoardService';
import { useMst } from '../../../models';
import ThemeContext from '../../../contexts/themeContext';
// import { adminsMenu } from '../../../menu';
import PaymentService from '../../../services/PaymentService';
// import { useChannelIOApi } from 'react-channel-plugin';
import moment from 'moment';
import CompanyService from '../../../services/CompanyService';
import { useCard } from '../../AuthMenu/Company/CardContext';
import LoginNoticeModal from './components/LoginNoticeModal';
const Login = () => {
  const { checkPinatState, setCheckPinatState } = useCard();
	
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const { t, i18n } = useTranslation(['translation', 'menu']);
	const { user, company } = useMst();
	const { setNoticeCount, lang, setLang } = useContext(ThemeContext);
	const [visiblePW, setVisiblePW] = useState(false);
	// const { updateUser } = useChannelIOApi(); // 새로운 버전에서 x
	const [currentCompanyPlan, setCurrentCompanyPlan] = useState(null);
	const [planList, setPlanList] = useState([]);
	const [companyInfo, setCompanyInfo] = useState({});
  const [isNoticeModalOpen, setIsNoticeModalOpen] = useState(false);
	const [noShowSevenDays, setNoShowSevenDays] = useState(false);
  useEffect(() => {
    const lastDismissed = localStorage.getItem('noticeDismissedDate');
    if (!lastDismissed || new Date().getTime() - new Date(lastDismissed).getTime() > 7 * 24 * 60 * 60 * 1000) {
      setIsNoticeModalOpen(true);
    }
  }, []);

  const toggleNoticeModal = () => {
    setIsNoticeModalOpen(!isNoticeModalOpen);
  };

  const handleDontShowForWeek = () => {
    localStorage.setItem('noticeDismissedDate', new Date().toISOString());
    setIsNoticeModalOpen(false);
  };

	const getCompanyInfo = async() => {
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path, extraMenu.changeCompany.path, pawsswordFind.PasswdReset.path];
		if(withOutAsidePages.findIndex(v => '/' + v === location.pathname) === -1){
			const response = await CompanyService.info({id: company.id});
			
			setCompanyInfo(response.data);
		}
	}
	//const webToken = getItem('webToken');

	useEffect(()=> {
		getCompanyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const loginData = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: (values) => {
			if (!values.email || !values.password) {
				showNotification(t('로그인'), t('필수 정보를 확인해주세요.'), 'danger');
			} else {
				requestLogin(values);
			}
		},
	});

	const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(()=>{
      moment.locale(lng);   
      showNotification(
        <span className='d-flex align-items-center'>
          <Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
          <span>{`${getLangWithKey(lng)?.text}`}</span>
        </span>,
        t('You updated the language of the site.'),
      );
    }).then(()=>{
      setLang(lng);
    })
  };

// 로그인 시 읽지 않은 게시물 카운트
	const readNoticeCount = async () => {
		const noticeCount = await BoardService.readCount('notice', user.me.currentCompanyId);
		setNoticeCount(noticeCount?.data || 0);
	 };

	const requestLogin = async (values) => {
		const loginData = await AuthService.login(values);
			// .then(async (res) => {
				if (loginData?.message) {
					// 로그인 실패 시
					showNotification(t('로그인'), t(loginData?.message), 'danger');
				} else {
					user.authMe().then(async () => {
						await Promise.all([
							Configs.sendDevice(),
							readNoticeCount(),
							user.me.currentCompanyId && company.selectCompany(user.me.currentCompanyId),
						]);
						// 새로운 버전에서 x
						// try {
						// 	updateUser({
						// 		memberId: user.me.id,
						// 		profile: {
						// 			name: user.me.name,
						// 			email: user.me.email,
						// 			avatarUrl: user.me.profile,
						// 			mobileNumber: user.me.phone,
						// 		},
						// 	});
						// } catch (e) {
						// 	console.log(e);
						// }

						const canUsePinat = (checkPinatState?.state === 'ACTIVE' || checkPinatState?.state === 'TRIAL_PAY_PENDING' || checkPinatState?.state === 'TRIAL_PAY_COMPLETED' || checkPinatState?.state === 'CANCEL_PENDING');

						if (user.me.id) {
							if(canUsePinat) {
								//결제 완료시 사용 가능한 경로
								user.me.currentCompanyId === 0 || user.me.currentCompanyId === null || user.me.currentCompanyId === undefined ? navigate('/change-company') : navigate('/');
							} else {
								//결제 해지시 결제관리 페이지로.
									navigate(adminsMenu.setting.subMenu.paymentPage.path);
							}
						}
					})
					.catch((e) => {
						console.log(e);
						showNotification(t('로그인'), t('로그인에 실패하였습니다.') + t('다시 확인해주세요') + '.', 'danger');
					})
					;
				}
			// })
			// .catch(() => {
			// 	showNotification(t('로그인'), t('로그인 실패'), 'danger');
			// })
			// .finally();
	};

	return (
		<PageWrapper title={'Login'} className={classNames('bg-info', 'main-bg')}>
			<Page>
				<div className='col-auto'>
					<Dropdown className='mb-4'>
						<DropdownToggle hasIcon={false}>
							<Button aria-label='Change language' data-tour='lang-selector' color={darkModeStatus ? 'dark' : 'light'} hoverShadow='default' isLight={!darkModeStatus} size='lg' icon={getLangWithKey(i18n.language).icon} />
						</DropdownToggle>
						<DropdownMenu>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button 
										icon={LANG[i].icon}
										disabled={lang === LANG[i].lng}
										onClick={()=> {
											changeLanguage(LANG[i].lng);
										}}
									>{LANG[i].text}</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				<div className='row h-100 align-items-center'>
					<div className='col-xl-4 col-lg-6 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Logo width={150} />
								</div>
								<div
									className={classNames('rounded-pill', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												className='rounded-pill w-100'
												size='lg'
												onClick={() => {
												}}>
												{t('로그인')}
											</Button>
										</div>

										<div className='col'>
											<Link to={'/' + demoPages.signUp.path}>
												<Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight
													className='rounded-pill w-100'
													size='lg'
													onClick={() => {
													}}>
													{t('회원가입')}
												</Button>
											</Link>
										</div>
									</div>
								</div>
								<div className='text-center my-5'>
									<div className='fw-bold h1'>{t('환영합니다')},</div>
									<div className='h4 text-muted'>{t('로그인 후 이용해주세요')}!</div>
								</div>

								<form onSubmit={loginData.handleSubmit}>
									<FormGroup className='mb-4' label={t('이메일')}>
										<Input id='email' className='py-3' type='text' value={loginData.values.email} onChange={loginData.handleChange} />
									</FormGroup>

									<FormGroup label={t('비밀번호')}>
										<InputGroup>
											<Input id='password' className='py-3' type={visiblePW ? 'text' : 'password'} value={loginData.values.password} onChange={loginData.handleChange} />
											<Button onClick={() => setVisiblePW(!visiblePW)}>
												<Icon icon={visiblePW ? 'Visibility' : 'VisibilityOff'} />
											</Button>
										</InputGroup>
									</FormGroup>

									<div className='my-5 py-3'>
										<Button
											id='login-btn'
											className='w-100 mb-3 py-3'
											color='info'
											type='submit'
											onClick={() => {
												if (!loginData.isValid) {
													showNotification(t('로그인'), t('아이디 또는 비밀번호가 틀렸습니다.'), 'danger');
												}
											}}>
											{t('로그인')}
										</Button>

										<Link to={'/' + pawsswordFind.PasswdReset.path}>
											<Button className='w-100 mt-1 py-3' id='resetPW-btn' color='light'>
												{t('비밀번호 찾기')}
											</Button>
										</Link>
									</div>
								</form>
							</CardBody>
						</Card>

						<div className='text-center'>
							<a
								href={'//pinat.co.kr/terms01.html'}
								target={'_blank'}
								rel='noopener noreferrer'
								className={classNames('link-light text-decoration-none me-3', {
									'link-light': false,
									'link-light': true,
								})}>
								{t('개인정보취급방침')}
							</a>
							<a
								href='//pinat.co.kr/terms.html'
								target={'_blank'}
								rel='noopener noreferrer'
								className={classNames('link-light text-decoration-none', {
									'link-light': false,
									'link-light': true,
								})}>
								{t('서비스 이용약관')}
							</a>
						</div>
					</div>
				</div>
				<LoginNoticeModal
					isOpen={isNoticeModalOpen}
					toggle={toggleNoticeModal}
					onDontShowForWeek={handleDontShowForWeek}
					setNoShowSevenDays={setNoShowSevenDays}
					noShowSevenDays={noShowSevenDays}
				/>
			</Page>
		</PageWrapper>
	);
};
export default Login;
