import React, { createContext, useState, useContext } from 'react';

const CardContext = createContext();

export const useCard = () => useContext(CardContext);

export const CardProvider = ({ children }) => {
  const [currentCardInfo, setCurrentCardInfo] = useState(null);
  const [checkPinatState, setCheckPinatState] = useState(null);
  const [planList, setPlanList] = useState([]);

  return (
    <CardContext.Provider value={{ currentCardInfo, setCurrentCardInfo, checkPinatState, setCheckPinatState, planList, setPlanList }}>
      {children}
    </CardContext.Provider>
  );
};