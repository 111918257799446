import React, { useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/bootstrap/Accordion';
import Button from '../../../../components/bootstrap/Button';
import moment from 'moment';
import Icon from '../../../../components/icon/Icon';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import Badge from '../../../../components/bootstrap/Badge';
import { maskCardNumber } from '../../../../lib/Util';
import PaymentService from '../../../../services/PaymentService';
import CustomedLeavePopovers from '../../../../components/CustomedLeavePopovers';
import showNotification from '../../../../components/extras/showNotification';
import StateAddCardModal from './StateAddCardModal';
import { useCard } from '../CardContext';
const PaymentEditModal = (props) => {
	const { t } = useTranslation(['translation, menu']);
	const { currentCardInfo, setCurrentCardInfo } = useCard();
	const { cardList, setCardList, companyInfo, fetchCardList, refresh } = props;
	const [modalState, setModalState] = useState({ isOpen: false, type: null, selectedCard: null });
	const [openAddCardModal, setOpenAddCardModal] = useState({ isOpen: false, success: true });

	const requestPayment = async () => {
		const cardDetails = {
			userCount: companyInfo?.userCount, // 사람 수
			planType: 1,
		};

		try {
			const response = await PaymentService.requestPayment(cardDetails);
			if (response.data.online_url) {
				window.open(response.data.online_url);
			} else {
				showNotification(t('결제 실패'), t('결제 실패 하였습니다'), 'danger');
			}

			// console.log('response : ', response)
			// if(response?.data?.token) {
			// 	showNotification(t('결제 성공'), t('결제 성공 하였습니다'), 'info');

			// }
		} catch (error) {
			console.error('결제 요청 에러:', error);
			showNotification(t('결제 실패'), t('결제 실패 하였습니다'), 'danger');
		}
	};

	const updateDefaultCardType = async (card) => {
		await PaymentService.updateDefaultCard(card);
	}

	const openModal = (type, card) => {
		setModalState({ isOpen: true, type, selectedCard: card });
	};
	const closeModal = () => {
		setModalState({ isOpen: false, type: null, selectedCard: null });
	};
	const openSuccessModal = (type) => {
		setOpenAddCardModal({ isOpen: true, success: type });
	};
	const closeSuccessModal = () => {
		setOpenAddCardModal({ isOpen: false, success: false });
	};

	const changeDefaultCard = (selectCard) => {
		setCurrentCardInfo(selectCard);
		updateDefaultCardType(selectCard);
		closeModal();
		setCardInfo(selectCard);
	};

	const deleteCard = (selectCard) => {
		// 카드 삭제 로직 추가
		
		PaymentService.delete(selectCard?.id)
		.then((response) => {
			// console.log(response);
			if (!response.data) {
				showNotification(t('카드삭제'), t('삭제가 완료 되었습니다'), 'info');
				const newCardList = [...cardList];
				
				const findIndex = newCardList?.findIndex((card) => card?.card_info === selectCard?.card_info);
				if (findIndex !== -1) {
					newCardList.splice(findIndex, 1);
				}
				setCardList(newCardList);
				fetchCardList();
		
				//기본 카드 삭제시 최상단 카드를 기본으로 변경
				// if (newCardList?.findIndex((card) => card?.card_info === currentCardInfo?.card_info) == -1) {
				closeModal();
			}
		})
	};

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.data && event.data.status === 'success') {
				// console.log(event.data.message); // 성공 메시지 알림
				fetchCardList();
				openSuccessModal(true);
			} else if (event.data && event.data.status === 'fail') {
				openSuccessModal(false);
				// console.log(event.data.message); // 실패 메시지 알림
			}
		};
		window.addEventListener('message', handleMessage, false);
		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('message', handleMessage, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const editOptions = (card) => {
		return (
			<div className=''>
				{currentCardInfo?.card_info !== card?.card_info && (
					<div>
						<Button
							onClick={(e) => {
								openModal('selectDefault', card);
							}}>
							{t('기본으로 변경')}
						</Button>
					</div>
				)}
				<div>
					<Button
						onClick={() => {
							openModal('deleteCard', card);
						}}>
						{t('삭제')}
					</Button>
				</div>
			</div>
		);
	};

	return (
		<>
			<ModalBody className='mx-3'>
				<div className='title text-center mb-4'>
					<h4 className='fw-bold'>{t('결제 수단 변경')}</h4>
				</div>

				<div className='card-info'>
					<div className='d-flex justify-content-between align-items-start'>
						<h6 className='fw-bold mb-3'>{t('등록된 결제수단')}</h6>
						<div
							className='text-info cursor-pointer'
							onClick={() => {
								requestPayment(); //open payLetter popup
							}}>
							<Icon icon='Add' />
							<span>{t('추가')}</span>
						</div>
					</div>
					<div className='card-info'>
						<div className=''>
							{cardList?.length > 0 && cardList?.map((card, index) => {
								return (
									<div key={`card-${index}`} className='d-flex justify-content-between align-items-center border p-4'>
										<div className='d-flex align-items-center'>
											<div className='first me-4'>
												<Icon icon='CreditCard' size='lg' />
											</div>
											<div className='second me-3'>
												<div>{maskCardNumber(card?.card_info)}</div>
												{/* {currentCardInfo ? currentCardInfo : maskCardNumber(card?.card_info)} */}
											</div>
											<div className='third'>{currentCardInfo?.card_info === card?.card_info ? <Badge>{t('기본')}</Badge> : null}</div>
										</div>
										{cardList?.length > 1 && (
											<div className=''>
												<CustomedLeavePopovers desc={<div>{editOptions(card)}</div>} trigger={'click'} setPopoverOpen={modalState.isOpen && false}>
													<Icon icon='MoreVert' style={{ marginLeft: 30, color: '#808080', cursor: 'pointer' }} size='lg' />
												</CustomedLeavePopovers>
											</div>
										)}
									</div>
								);
							})}
							{cardList?.length < 1 && <div className='text-muted text-center mt-5'>{t('등록된 카드가 없습니다') + '.'}</div>}
						</div>
					</div>
				</div>
				<Modal isOpen={modalState.isOpen} setIsOpen={closeModal} isCentered size={'sm'}>
					<ModalBody>
						{modalState.type === 'selectDefault' ? (
							<div className='text-center fw-bold lh-lg mt-5'>
								<span>
									{t('선택하신 카드를')}
									<br />
									{t('기본으로 변경하시겠어요') + '?'}
								</span>
							</div>
						) : (
							<div className='text-center fw-bold mt-5'>
								<span>{t('결제수단을 삭제하시겠어요') + '?'}</span>
							</div>
						)}
					</ModalBody>
					<ModalFooter className='d-flex justify-content-center mb-2'>
						<Button className='px-5 py-1' color='light' rounded={0} isOutline onClick={closeModal}>
							{t('취소')}
						</Button>
						{modalState.type === 'selectDefault' ? (
							<Button
								className='px-5 py-1'
								color='info'
								rounded={0}
								onClick={() => {
									changeDefaultCard(modalState?.selectedCard);
								}}>
								{t('변경')}
							</Button>
						) : (
							<Button
								className='px-5 py-1'
								color='info'
								rounded={0}
								onClick={() => {
									deleteCard(modalState?.selectedCard);
								}}>
								{t('삭제')}
							</Button>
						)}
					</ModalFooter>
				</Modal>
			</ModalBody>
			<StateAddCardModal openAddCardModal={openAddCardModal} closeSuccessModal={closeSuccessModal} />
			{/* <Modal size={'sm'} isOpen={openAddCardModal.isOpen} toggle={closeSuccessModal} isCentered>
      <ModalBody className='text-center mt-4'>
        <div className='mb-5'>
          <Icon icon='CreditCard' size='3x' color={openAddCardModal.success ? 'info' : 'danger'} />
        </div>
        {openAddCardModal.success ? (
          <div className='fw-bold fs-4 my-4'>
            <span>{t('카드 등록이')}</span>&nbsp;
            <span className='text-info'>{t('완료')}</span>
            <span>{t('되었습니다') + '.'}</span>
          </div>
        ) : (
          <div className='fw-bold fs-4 my-4'>
            <span>{t('카드 등록에')}</span>&nbsp;
            <span className='text-danger'>{t('실패')}</span>
            <span>{t('하였습니다') + '.'}</span>
          </div>
        )}
      </ModalBody>
      <ModalFooter className='d-flex justify-content-center mb-4'>
        <Button className='px-5' rounded={1} color='info' onClick={closeSuccessModal}>
          {t('확인')}
        </Button>
      </ModalFooter>
    </Modal> */}
			{/* <ModalFooter className='d-flex justify-content-start'>
				<Button
					onClick={() => {
						setOpenEditModal(false);
					}}>
					{t('이전')}
				</Button>
				</ModalFooter> */}
			{/* </Modal> */}
		</>
	);
};
export default React.memo(PaymentEditModal);
