import { APIClient } from "../lib/apiHelper";

const { get, post, put, remove } = new APIClient();

export default {
    
    requestPayment: payment => post("/pay/payRequest", payment),

    resultPayment: () => post("/pay/payProcess"),
    
    paymentHistory: () => get(`/pay/paymentHistory`),

    updatePlanType: (value) => post(`/pay/updateType`, value),

    getCardInfo: () => get("/card/getCardList"),
    
    getPlan: () => get("/plan/getPlanList"),
    
    getType: () => get("/plan/getType"),

    getUpdatePinatState: () => get("/plan/updatePinatState"),

    updateDefaultCard: value => post("/card/updateDefaultCard", value),

    delete: (id) => remove('/card?id=' + id),
}
