import { saveAs } from "file-saver";
import moment from "moment";
import showNotification from "../components/extras/showNotification";
import CompanyService from "../services/CompanyService";
import { useEffect, useRef } from 'react';

export function divisionArray(arr, n) {
    const len = arr.length;
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
    let tmp = [];
    for (var i = 0; i < cnt; i++) {
        tmp.push(arr.splice(0, n));
    }
    return tmp;
}

export const checkMoment = (input, format='YYYY-MM-DD') => {
    const formats = ["YYYY-MM-DD", "YYYYMMDD", "YYYY_MM_DD", "YYYY/MM/DD", "MM/DD/YYYY", "MM-DD-YYYY", "MM_DD_YYYY"]; // 이후 계속 추가
    for(let i=0; i<formats.length; i++) {
      if(moment(input, formats[i]).isValid() === true)
      return moment(input, formats[i]).format(format);
    }
    return input;
  }
  
export function s2ab(s) { 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}

export async function verificationFile(file, validTableData, userCompanyList, companyId, type) {
    
    if (file) {
        return new Promise(async (resolve)=>{
            const xlsx = require("xlsx");
            const reader = new FileReader();
            switch (type) {
                
                case 'rest':
                    // 사용자 검증 / 사용 시작일자 / 사용 종료일자 / 휴가여부
                    let resRestType = await CompanyService.getRestType(companyId)
                    let restType = resRestType.data

                    reader.onload = (e) => {
                        let userList = userCompanyList.map(x => x.user)
                        let result = true
                        let target = e.target.result
                        const wb = xlsx.read(target, { type: 'binary' })
                        const sheetname = wb.SheetNames[0]
                        let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname],{raw: false})
                        
                        const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
            

                        for (let i = 0; i < data.length; i++) {
                            // console.log(data[i]['사용 시작일자'], (data[i]['사용 시작일자']).indexOf('-'))
                            //사용 시작일자 유효성 체크
                            if((data[i]['사용 시작일자']).indexOf('-') < 0) {
                                data[i]['사용 시작일자'] = data[i]['사용 시작일자'].split('/').map(x=>x.length == 1 ? '0'+x : x).join('/')                                                        
                                // console.log('A', data[i]['사용 시작일자'])
                                if (!moment(data[i]['사용 시작일자'], 'MM/DD/YY', true).isValid()) {
                                    data[i]['사용 시작일자'] = validTableData?.length > 0? data[i]['사용 시작일자'] : moment(data[i]['사용 시작일자'], 'MM/DD/YY').format('YYYY-MM-DD')
                                    data[i]['사용 시작일자'] = data[i]['사용 시작일자'].indexOf('#') > -1 ?
                                    data[i]['사용 시작일자'] :  '#' + data[i]['사용 시작일자'] + '#'
                                    result = false
                                } else {
                                    data[i]['사용 시작일자'] = moment(data[i]['사용 시작일자'], 'MM/DD/YY').format('YYYY-MM-DD');
                                }
                            } else {
                                // console.log('B')
                                if (!moment(data[i]['사용 시작일자'], 'YYYY-MM-DD', true).isValid()) {
                                    data[i]['사용 시작일자'] = validTableData?.length > 0? data[i]['사용 시작일자'] : moment(data[i]['사용 시작일자'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                                    data[i]['사용 시작일자'] = data[i]['사용 시작일자'].indexOf('#') > -1 ?
                                    data[i]['사용 시작일자'] :  '#' + data[i]['사용 시작일자'] + '#'
                                    result = false
                                }
                            }
                            //사용 종료일자 유효성 체크
                            if((data[i]['사용 종료일자']).indexOf('-') < 0) {
                                data[i]['사용 종료일자'] = data[i]['사용 종료일자'].split('/').map(x=>x.length == 1 ? '0'+x : x).join('/')                                                        
                                // console.log('A', data[i]['사용 시작일자'])
                                if (!moment(data[i]['사용 종료일자'], 'MM/DD/YY', true).isValid()) {
                                    data[i]['사용 종료일자'] = validTableData?.length > 0? data[i]['사용 종료일자'] : moment(data[i]['사용 종료일자'], 'MM/DD/YY').format('YYYY-MM-DD')
                                    data[i]['사용 종료일자'] = data[i]['사용 종료일자'].indexOf('#') > -1 ?
                                    data[i]['사용 종료일자'] :  '#' + data[i]['사용 종료일자'] + '#'
                                    result = false
                                } else {
                                    data[i]['사용 종료일자'] = moment(data[i]['사용 종료일자'], 'MM/DD/YY').format('YYYY-MM-DD');
                                }
                            } else {
                                // console.log('B')
                                if (!moment(data[i]['사용 종료일자'], 'YYYY-MM-DD', true).isValid()) {
                                    data[i]['사용 종료일자'] = validTableData?.length > 0? data[i]['사용 종료일자'] : moment(data[i]['사용 종료일자'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                                    data[i]['사용 종료일자'] = data[i]['사용 종료일자'].indexOf('#') > -1 ?
                                    data[i]['사용 종료일자'] :  '#' + data[i]['사용 종료일자'] + '#'
                                    result = false
                                }
                            }


                            const checkValid = (str) => {
                                str = str.replace(/ /g, '') // 공백 제거
                                return str.charAt(0) == '#' && str.charAt(str.length-1) == '#' ? str.substring(1,str.length-1) : str // '#'있다면 제거
                            }
                            // 이메일 유효성체크 준비            
                            data[i]['이메일'] = checkValid(data[i]['이메일'])

                            //이메일 형식 체크
                            if (data[i]['이메일'].length < 6 || !regExpEmail.test(data[i]['이메일'])) {
                                data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ?
                                data[i]['이메일'] :  '#' + data[i]['이메일'] + '#'
                                result = false
                            }

                            //사용자 유효성 체크
                            if (!userList.find(x => x.name == data[i]['이름'].replace(/ /g, '') && x.email == data[i]['이메일'])) {
                                data[i]['이름'] = data[i]['이름'].indexOf('#') > -1 ?
                                data[i]['이름'] : '#' + data[i]['이름']+ '#'

                                data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ?
                                data[i]['이메일'] :  '#' + data[i]['이메일'] + '#'
                                result = false
                            }

                            // 휴가 여부 체크
                            if (!restType.find(x => x.name == data[i]['휴가유형'].replace(/ /g, ''))) {
                                data[i]['휴가유형'] = data[i]['휴가유형'].indexOf('#') > -1 ?
                                data[i]['휴가유형'] :  '#' + data[i]['휴가유형'] + '#'
                                result = false
                            }
                        }
                        resolve({data : data, result : result})
                    }
                    reader.readAsBinaryString(file)
                    break;
                default:
                    // 직원등록,직원초대 유효성 체크
                    //휴대폰번호 / 입사일 / 이메일

                    reader.onload = (e) => {
                        let target = e.target.result
                        const wb = xlsx.read(target, { type: 'binary' })
                        const sheetname = wb.SheetNames[0]
                        let data = validTableData?.length > 0 ? validTableData : xlsx.utils.sheet_to_json(wb.Sheets[sheetname],{raw: false})

                       // const patternPhone = new RegExp("01[016789]-[0-9]{2,3}-[0-9]{3,4}");  
                        //const patternPhone = new RegExp("01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}");  
                        const patternPhone = /01[016789]-[0-9]{3,4}-[0-9]{4}/;
                        const regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
                        let emails = []
                        let result = true

                        for (let i = 0; i < data.length; i++) {
                            let verificationData = {}
                            //휴대폰 번호 형식
                            if (!patternPhone.test(data[i]['휴대폰번호'])) {
                                data[i]['휴대폰번호'] = data[i]['휴대폰번호'].indexOf('#') > -1 ?
                                data[i]['휴대폰번호'] :  '#' + data[i]['휴대폰번호'] + '#'
                                result = false
                            }
                            //입사일 유효성 체크
                            //MM/DD/YY
                            if(data[i]['입사일'].search('/') > -1){
                                data[i]['입사일'] = data[i]['입사일'].split('/').map(x=>x.length == 1 ? '0'+x : x).join('/')

                                if (!moment(data[i]['입사일'], 'MM/DD/YY', true).isValid()) {
                                    data[i]['입사일'] = data[i]['입사일'].indexOf('#') > -1 ?
                                    data[i]['입사일'] :  '#' + data[i]['입사일'] + '#'
                                    result = false
                                }
                            }else{
                                //YYYY-MM-DD
                                if (!moment(data[i]['입사일'], 'YYYY-MM-DD', true).isValid()) {
                                    data[i]['입사일'] = data[i]['입사일'].indexOf('#') > -1 ?
                                    data[i]['입사일'] :  '#' + data[i]['입사일'] + '#'
                                    result = false
                                }
                            }
                            // 이메일 유효성체크 준비            
                            let email = data[i]['이메일'].replace(/ /g, '') // 공백 제거
                            //이메일 중복 체크

                            if (emails.find(x => x === email)) {
                                data[i]['이메일'] = '중복'
                                result = false
                            } else {
                                emails.push(email)
                            }
                            //이메일 형식 체크
                            if (email.length < 6 || !regExpEmail.test(email)) {
                                data[i]['이메일'] = data[i]['이메일'].indexOf('#') > -1 ?
                                data[i]['이메일'] :  '#' + data[i]['이메일'] + '#'
                                result = false

                            }

                        }
                        resolve({data : data,result : result})
                    }
                    reader.readAsBinaryString(file)
                    break;
            }
        })
        }else{
        showNotification('유효성 검사', '파일이 존재하지 않습니다.', 'danger')
        return false
    }

}

export async function excelDownload( jsonDataList, key, options ){
    const xlsx = require("xlsx")
    let workSheetDatas = []
    let sheetName
    console.log('jsonDataList >> ',jsonDataList)
    switch(key){
        case 'userList':
            sheetName = '사원정보'
            jsonDataList.map(x => {
                let attnedType = [];
                x?.group?.area?.gps && attnedType.push('GPS');
                x?.secomNumber && attnedType.push('출입연동');
                x?.group?.area?.wifi && attnedType.push('WIFI');
             

                let deviceModel = []
                deviceModel = x.device.map(y=>y.os)
                deviceModel = new Set(deviceModel)
                deviceModel = [...deviceModel]
               
                let state = ''
                switch (x.state) {
                    case 'SUCCESS':
                        state = '재직'
                        break;
                    case 'LEAVE':
                        state = '퇴직'
                        break;
                    case 'REQUEST':
                        state = '가입 요청'
                        break;
                    case 'DENY':
                        state = '요청 거절'
                        break;
                }

                let depart = x.departments?.find(x=>x.main)

                let workSheetData = {
                    이름: x.user?.name || '',
                    이메일: x.user?.email||'',
                    휴대폰: x.user?.phone || '',
                    부서:  depart?.department?.name ,
                    소속그룹: x.group?.name || '',
                    직급: x.rank?.name || '',
                    직책: x.position?.name || '',
                    인증방식: attnedType?.join('/') || '',
                    연차: `${((x.restInfo?.rest || 0) - (x.restInfo?.userest || 0)).toFixed(1)} / ${(x.restInfo?.rest).toFixed(1)}`,
                    권한: x.role?.name || '',
                    디바이스: deviceModel?.join('/') ||'',
                    근무상태: state,
                }
                workSheetDatas.push(workSheetData)
            })
            break;
        default : 
            break;
    }
    let wb = xlsx.utils.book_new();
    let ws = xlsx.utils.json_to_sheet(workSheetDatas)
    xlsx.utils.book_append_sheet(wb, ws, sheetName)
    xlsx.writeFile(wb,  `${moment().format('YYYY-MM-DD')}_${sheetName}.xlsx`);
    
}

export const convertMinsToHrsMins = (mins) => {
	let h = Math.floor(mins / 3600);
	let m = Math.floor((mins % 3600) / 60);
	let s = Math.floor(mins % 60);
	//let h = Math.floor(mins / (60 * 60));
	//let m = mins % (60);
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	return `${h}:${m}`;
};


export const getLineUser = (approvalList = []) => {
    const userList = []
    approvalList?.forEach(approval => {
      approval.line.forEach(user => {
        userList.push(user.user)
      })
    })
    return userList;
  }

export const getTitle = (request) => {
    if (request?.title !== '' && request?.title !== null) {
        return request?.title;
    } else {
      switch (request.type) {
        case 'RECORD':
          return '근무기록 수정';
        case 'WORK':
        case 'REST':
        case 'USER_REST':
        case 'REST_TRANSFER':
        case 'REST_CUSTOM':
          return `${request.restday.name} 신청`;
        case 'WORK_CANCEL':
        case 'USER_REST_CANCEL':
        case 'REST_CUSTOM_CANCEL':
        case 'REST_TRANSFER_CANCEL':
        case 'REST_CANCEL':
          return `${request?.restday?.name} 신청 취소`;
        default:
          return '-';
      }
  }};

  export const priceFormat = (price) => {
    return (price || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  export const maskCardNumber = (cardNum) => {
    let card = cardNum || '';
    const lastFourDigits = card?.slice(-4);
    const maskedDigits = card?.slice(0, -4).replace(/\d/g, '*');
    const maskedCardNumber = maskedDigits + lastFourDigits;

    return maskedCardNumber.replace(/(.{4})/g, '$1 ').trim();
};

export function useTimeout(callback, delay) {
    const savedCallback = useRef();

    // 최신 콜백을 저장
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // 타이머 설정
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setTimeout(tick, delay);
        return () => clearTimeout(id);
      }
    }, [delay]);
}
