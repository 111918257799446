import React, { useEffect } from 'react';
import Modal, { ModalBody, ModalFooter} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import { useTranslation } from 'react-i18next';
import CustomedModal from '../../../../components/bootstrap/CustomedModal';

const LoginNoticeModal = ({ isOpen, toggle, onDontShowForWeek, noShowSevenDays, setNoShowSevenDays }) => {
  const {t} = useTranslation(['translation, menu']);

  useEffect(()=> {
    if(!isOpen){
      if(noShowSevenDays) {
        onDontShowForWeek();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, noShowSevenDays]);
  return (
    <CustomedModal size='sm' modalColor={'#131C6D'} isOpen={isOpen} setIsOpen={toggle}>
      <ModalBody className="text-center mt-4" >
        <div>
          <h3 className='fw-bold text-white'>{t('Pin@ 근태관리')}</h3>
          <p className='fw-bold fs-3 text-warning'>{t('유료 전환 안내')}</p>
        </div>
        <div className='bg-white p-4 mx-3 rounded fw-normal' style={{color: '#131C6D'}}>
          <span>{t('핀앳에서 무료로 제공하던 근태관리 서비스가 더욱 안정성있는 서비스 제공을 위하여 2024년 8월 1일부터 유료 전환됩니다. 이후에는')}</span>&nbsp;
          <span className='fw-bold'>{t('요금제 선택 및 무료체험 신청')}</span>
          <span>{t('으로 근태관리 서비스를 이용하실 수 있습니다. 앞으로도 핀앳에 지속적인 관심 부탁드리며, 양질의 서비스로 보답하겠습니다') + '.'}</span>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button icon={!noShowSevenDays ? 'CheckBoxOutlineBlank' : 'CheckBox'} style={{color: 'white'}} onClick={()=> {setNoShowSevenDays(!noShowSevenDays)}}><span>{t('일주일간 보지 않기')}</span></Button>
        <Button color="info" onClick={toggle}>{t('닫기')}</Button>
      </ModalFooter>
    </CustomedModal>
  );
};

export default LoginNoticeModal;