import { APIClient } from '../lib/apiHelper';

const { get, post, put, remove } = new APIClient();

export default {
	list: (type, comapnyId, query=null, category=null) => get(`/board/${comapnyId}/${type}${category ? `?category=${category}&${query ? new URLSearchParams(query).toString() : ''}` : `?${query ? new URLSearchParams(query).toString() : ''}`}`),
    view: (type, comapnyId, idx) => get(`/board/${comapnyId}/${type}/${idx}`),
	add: (type, comapnyId, data) => post(`/board/${comapnyId}/${type}`, data),
	update: (type, comapnyId, idx, data) => put(`/board/${comapnyId}/${type}/${idx}`, data),
	delete: (type, comapnyId, idx) => remove(`/board/${comapnyId}/${type}/${idx}`),
	readCount: (type, comapnyId) => get(`/board/${comapnyId}/${type}/readCnt`),

    deleteImage: (idx, index) => remove(`/board/image/${idx}/${index}`),
	uploadImage: (data) => post(`/board/file/upload`, data),
    download: (idx, index) => get(`/board/file/${idx}/${index}`),
    // REPLY
    replyList: (type, comapnyId, idx) => get(`/reply/${comapnyId}/${type}/${idx}`),
    replyView: (type, comapnyId, idx, ridx) => get(`/reply/${comapnyId}/${type}/${idx}/${ridx}`),
	replyAdd: (type, comapnyId, idx, data) => post(`/reply/${comapnyId}/${type}/${idx}`, data),
	replyUpdate: (type, comapnyId, ridx, data) => put(`/reply/${comapnyId}/${type}/${ridx}`, data),
	replyDelete: (type, comapnyId, ridx) => remove(`/reply/${comapnyId}/${type}/${ridx}`),
};
