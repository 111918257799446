let host = window.location.host.indexOf('localhost') > -1 ? 'local' : window.location.host.replace('work.pinat.co.kr', '')
if (!host) host = 'prod'

let appVersion = ''
let buildVersion = 'D2564'

const config = {
  local: {
    CLIENT_ID: 'kr.co.pinat',
    API_URL: "http://localhost:11061/api/v1",
    KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
  },
  dev: {
    API_URL: "https://devapi.pinat.co.kr/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
  },
  qa: {
    API_URL: "https://qaapi.pinat.co.kr/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
  },
  prod: {
    API_URL: "https://api.pinat.co.kr/api/v1",
    CLIENT_ID: 'kr.co.pinat',
    KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
    REACT_APP_BUSINESS_REGISTRATION_INFORMATION_API_KEY: "f2OpoyBkYHmpFj9mTkmaCW8l%2B8qy51ejekkAN8OYspXTuyQs%2FT%2BJsvNtgjzDtsSvWTIWnF%2Bn1s6OtoBQNZvDTg%3D%3D",
    appVersion,
    buildVersion,
  },
}

export default config[host]
