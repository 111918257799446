import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import { componentsMenu, dashboardMenu, demoPages, layoutMenu, commonsMenu, adminsMenu, footsMenu, usersMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import { Link, useNavigate } from 'react-router-dom';
import { useMst } from '../../models';
import { observer } from 'mobx-react';
import Badge from '../../components/bootstrap/Badge';
import CompanyService from '../../services/CompanyService';
import { setItem, getItem } from '../../lib/localstorage';


const Aside = observer(() => {
	const { asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus, asideSubMenuStatus, asideMenu, noticeCount } = useContext(ThemeContext);
	const { asideStyle, asideSubStyle, touchStatus, hasTouchButton, asideWidthWithSpace, asideSubWidthWithSpace, x, x2 } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const { user, company, navtabmenus } = useMst();

	const [companyInfo, SetCompanyInfo] = useState({});
	const [admin, setAdmin] = useState({});
	const navigation = useNavigate();


	const cleaerRestInfo = async () => {
		await company.setRestInfo({})
	}
	const setRestInfo = async () => {			
		await CompanyService.restdayInfo({ companyId: company.id, userId: user.id }).then( async res => {
			// console.log('res >> ',res)
			await company.setRestInfo(res.data);
		});						
	};
	const getCompanyInfo = async () => {			
		await CompanyService.info({id:company.get.id}).then( async res => {
			// console.log('res >> ',res)
			if(res?.data) {
				SetCompanyInfo(res?.data);
			}
			// await company.setRestInfo(res.data);
		});						
	};

	useEffect(()=> {
		getCompanyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	useEffect(() => {
		// console.log("----------------", JSON.stringify([]));
		const navTabls = JSON.parse(getItem(`navTabMenu_${company.get.id}_${company.get.isDoc ? 'admin':'user'}`) || JSON.stringify([]));
		// console.log('load tabs', navTabls);
		if(navtabmenus?.setMenus)
			navtabmenus?.setMenus(navTabls);

		if (company.get.isDoc) {
			// console.log('admin');
			cleaerRestInfo();
		} else {
			// console.log('user');
			setRestInfo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.get.isDoc, navtabmenus]);

	
	useEffect(()=> {
		if (!adminsMenu?.setting) return;

		const { paymentPage, ...noPaymentPage } = adminsMenu.setting.subMenu;
	
		const updatedSubMenu = companyInfo?.ceo?.id === user.me?.id 
			? adminsMenu.setting.subMenu 
			: noPaymentPage;
	
		const updatedAdminsMenu = {
			...adminsMenu,
			setting: {
				...adminsMenu.setting,
				subMenu: updatedSubMenu
			}
		};
	
		setAdmin(updatedAdminsMenu);
	
	}, [companyInfo, user])

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					}
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} asideSubStatus={asideSubStatus} setAsideSubStatus={setAsideSubStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={commonsMenu} id='aside-dashboard' />
					{/* <div className='navigation'>
						<div
							className='navigation-item cursor-pointer'
							onClick={() => {
								// popUp Modal Notice~~
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon={'Campaign'} className='navigation-icon color-blue-light' />
									<span className='navigation-text'>{t('공지사항')}</span>
								</span>
								<span className='navigation-link-extra'>
									<Badge color='danger'>1</Badge>
								</span>
							</span>
						</div>
					</div> */}

					<NavigationLine />

					{/*  사용자 전용 메뉴 */}
					{!company.get.isDoc && (
						<>
							<Navigation menu={usersMenu} id='aside-menu-two' />
							{/* <NavigationLine />
							<Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' /> */}
						</>
					)}

					
					{/*  관리자 전용 메뉴 */}
					{company.get.isDoc && (
						<>
							<Navigation menu={admin} id='aside-menu-two' />
							{/* <Navigation menu={adminsMenu} id='aside-menu-two' /> */}
						</>
					)}

					{/* <Navigation menu={demoPages} id='aside-demo-pages' />
					<Navigation menu={layoutMenu} id='aside-menu' /> */}
					{/* <Navigation menu={componentsMenu} id='aside-menu-two' /> */}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						{/* <Navigation menu={footsMenu} id='aside-foots' /> */}
						<div className='navigation'>
							<div
								className='navigation-item cursor-pointer'
								onClick={() => {
									window.open(`https://help.pinat.co.kr/${company.get.isDoc ? 'admin' : 'user'}`, '_blank');
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon={'LibraryBooks'} className='navigation-icon color-blue-light' />
										<span className='navigation-text'>{t('이용 가이드')}</span>
									</span>
								</span>
							</div>
						</div>

						{companyInfo?.isAdmin && (
							<div className={`navigation ${company.get.isDoc ? 'admin-state' : 'user-state'}`}>
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => {
										company.setIsAdmin(!company.get.isDoc);
										// 상단 탭 불러오기
										navigation('/');
									}}
									data-tour='documentation'>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info text-white'>
											<Icon
												icon={company.get.isDoc ? 'ToggleOn' : 'ToggleOff'}
												// color={company.get.isDoc ? 'success' : null}
												className='navigation-icon'
											/>
											<span className='navigation-text'>{company.get.isDoc ? t('관리자 상태') : t('사용자 상태')}</span>
										</span>
									</span>
								</div>
							</div>
						)}
					</nav>
					<User />
				</div>
			</motion.aside>

			{/* SUB */}
			{asideSubMenuStatus && (
				<motion.aside
					style={asideSubStyle}
					className={classNames(
						'aside-sub',
						//{ 'd-none': !asideSubStatus && hasTouchButton && isModernDesign },
						{ open: asideSubStatus },
						{
							'aside-touch-bar': hasTouchButton && isModernDesign,
							'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
							'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
						}
					)}>
					<div className='aside-sub-head'></div>
					<div className='aside-sub-body'>
						<Navigation menu={asideMenu} id='aside-sub-dashboard' />
					</div>
					<div className='aside-sub-foot'></div>
				</motion.aside>
			)}

			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title={t('Toggle Aside')} flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => {
								// console.log('x.get()', x.get())
								// console.log('x2.get()', x2.get())
								// console.log('>>', x2.get() === 0 ? asideSubWidthWithSpace : 0)
								x.set(x.get() === 0 ? asideWidthWithSpace : 0);
								x2.set(x2.get() === 0 ? asideSubWidthWithSpace : 0);
							}}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
});

export default Aside;
