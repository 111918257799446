
import moment from "moment";
import { Psychology } from "../components/icon/material-icons"
import { APIClient } from "../lib/apiHelper"

const {get, post, put, remove} = new APIClient()


export default {
	/**
	 * 회사 검색
	 * @method GET
	 * @param {OBJECT} search
	 * =========================
	 * @param {STRING} name
	 * @param {STRING} tel
	 * @param {STRING} address
	 * @param {STRING} count
	 * @param {STRING} offset
	 * @param {STRING} order
	 * @param {STRING} desc
	 */
	search: (search) => get(`/company?${search ? new URLSearchParams(search).toString() : ''}`),

	me: (companyId) => get('/company/me?companyId=' + companyId),

	info: (search) => get(`/company/info?${search ? new URLSearchParams(search).toString() : ''}`),

	personalInfo: (companyId, userId) => get('/personalInfo?companyId=' + companyId + '&userId=' + userId),

	restInfoList: (param) => post('/restInfoList', param),
	searchRequestList: (param) => post('/requestList', param),
	attendList: (companyId) => get('/attend/list?companyId=' + companyId),
	requestProceedingList: (companyId) => get('/request/proceed/list?companyId=' + companyId),
	attendCheckList: (companyId) => get('/attend/check/list?companyId=' + companyId),
	getVacationStatus: (info) => get(`/request/success/list?${info ? new URLSearchParams(info).toString() : ''}`),
	getRestOrWorkStatus: (info) => get(`/request/success/getRestOrWorkStatus?${info ? new URLSearchParams(info).toString() : ''}`),
	getRquestDetail: (id) => get(`/request/detail?id=${id}`),
	/**
	 * 사업자 등록번호 중복 조회
	 */
	checkCorporateRegiNumber: (number) => get('/company/check/regiNumber?number=' + number),
	/**
	 * 회사 가입 여부 확인
	 */
	checkJoinedCompany: (email, companyId) => get(`/company/join/check?email=${email}` + '&companyId=' + companyId),
	/**
	 * 직원 개별 등록
	 */
	singleAdd: (invate) => post('/userCompany/joinCompanyDirect/single', invate),

	/**
	 * 연차 엑셀 다운로드
	 * @param {INTEGER} selectedYear
	 */
	restRecordDownload: (info) => get(`/request/rest/download?${info ? new URLSearchParams(info).toString() : ''}`),

	/**
	 * 신청 목록
	 * @returns
	 */
	requestList: (request) => get(`/request/list?${request ? new URLSearchParams(request).toString() : ''}`),

	/**
	 * 연차 신청 목록
	 * @method GET
	 * @param {INTEGER} companyId
	 */
	restdayRequestList: (companyId) => get('/attend/restday-request?companyId=' + companyId),
	/**
	 * 회사 생성
	 * @method POST
	 * @param {OBJECT} companyAnniversary
	 * =========================
	 * @param {STRING} name
	 * @param {STRING} tel
	 * @param {STRING} address
	 * @param {STRING} addressDetail
	 * @param {STRING} logo
	 */
	create: (company) => post('/company', company),

	/**
	 * 참여한 회사 목록
	 * @method GET
	 */
	isExistCompany: (tel, companyId = 0) => get('/company/is-exist-company?tel=' + tel.replace(/-/gi, '') + '&companyId=' + companyId),

	/**
	 * 회사 참여
	 * @method POST
	 * @param {INTEGET} companyId
	 */
	joinRequest: (companyId) => put('/company/join-request', { companyId }),
	joinRequestConfirm: (data) => put('/company/join-request-confirm', data),
	joinRequestDeny: (id) => put('/company/join-request-deny', { id }),
	joinRequestList: (companyId) => get('/company/new-face?companyId=' + companyId),

	/**
	 * 참여한 회사 목록
	 * @method GET
	 * @param {OBJECT} search
	 * =========================
	 * @param {STRING} name
	 * @param {STRING} tel
	 */
	myCompany: (search) => get(`/company/my?${search ? new URLSearchParams(search).toString() : ''}`),

	/**
	 * 휴일 떙겨오기
	 * @param companyId
	 */
	newList: (companyId) => get('/holiday/newList?companyId=' + companyId),
	/**
	 * 휴일 추가
	 * @param {OBJECT} holiday
	 */
	addHoliday: (holiday) => post('/holiday/add', holiday),

	/**
	 * 휴일 삭제
	 * @method Remove
	 * @param {INTEGER} companyId
	 * @param name
	 */
	deleteHoliday: (companyId, name) => remove(`/holiday/delete?companyId=${companyId}&name=${name}`),

	/**
	 * 휴일 수정
	 * @method put
	 * @param {OBJECT} holiday
	 *
	 */
	putHoliday: (holiday) => put(`/holiday/put`, holiday),
	/**
	 * 회사 정보 수정
	 * @param data
	 * @returns {Promise<AxiosResponse<any>>}
	 * 기존 /company/modifyCompany
	 */
	modifyCompany: (data) => put('/company/modifyCompanyNew', data),

	/**
	 * 출근/퇴근 신청
	 * @method POST
	 * @param {INTEGER} companyId
	 * @param {INTEGER} areaId
	 * @param {STRING} attendType (ATTEND || LEAVE)
	 * @param {STRING} memo
	 */
	attend: (attend) => post('/attend', attend),

	/**
	 * 출근/퇴근 수정 요청
	 * @method PUT
	 * @param {INTEGER} id
	 * @param {STRING} memo
	 * @param {DATE} updatedAt
	 */
	attendModifyRequest: (attend) => put('/attend/modify-request', attend),

		/**
	 * 연차 신청 취소
	 * @param {*} detailId
	 */
	cancellationRequest: (detailId, etc=null, state=null) => remove('/request/remove?detailId=' + detailId + (etc ? '&etc=' + etc : '') + (state ? '&state=' + state : '')),

	/**
	 * 출근/퇴근 수정 (웹관리자용)
	 * @param {*} companyId
	 * @returns
	 */
	modifyWorkRecordRequest2: (attend) => post('/request/modify-work-record2', attend),
	modifyWorkRecordRequest3: (attend) => post('/request/modify-work-record3', attend),
	/**
	 * 출근/퇴근 GPS 수정 (웹관리자용)
	 * @param {*} companyId
	 * @returns
	 */
	modifyWorkGpsRequest: (attend) => post('/request/modify-gps-record', attend),

	/**
	 * 연차 종류 목록
	 * @method GET
	 * @param {INTEGER} companyId
	 */
	restDayList: (companyId) => get(`/restday?companyId=${companyId}`),

	/**
	 * 연차 신청
	 * @method POST
	 * @param {OBJECT} restday
	 * =========================
	 * @param {INTEGER} companyId
	 * @param {INTEGER} restdayId
	 * @param {STRING} memo
	 * @param {DATE} createdAt
	 */
	restdayRequest: (restday) => post('/attend/restday-request', restday),

	/**
	 * 출근/퇴근 수정 요청 V2
	 * @method POST
	 * @param {OBJECT} workRecord
	 * =========================
	 * @param {INTEGER} companyId
	 * @param {INTEGER} restdayId
	 * @param {INTEGER} areaId
	 * @param {STRING} type
	 * @param {STRING} startTime
	 * @param {STRING} endTime
	 * @param {ARRAY} approval
	 * @param {STRING} state
	 * @param {STRING} memo
	 * @param {STRING} etc
	 * @param {DATE} startDate
	 * @param {DATE} endDate
	 * @param {OBJECT} addHistory
	 *
	 */
	modifyWorkRecord: (workRecord) => post('/request/modify-work-record', workRecord),

	/**
	 * 연차 신청 승인
	 * @method PUT
	 * @param {INTEGER} id
	 * @param {BOOLEAN} ok
	 */
	restdayRequestConfirm: (id, ok) => put('/restday/request-confirm', { id, ok }),

	/**
	 * 신청 승인/반려
	 * @returns
	 */
	requestAccept: (request) => post('/request/accept', request),
	/**
	 * 사용자 일괄 신청 승인/반려
	 * @returns
	 */
	requestAcceptAll: (request) => post('/request/accept-all', request),

	/**
	 * 사용자 목록
	 * @method GET
	 * @param {INTEGER} companyId
	 */
	userList: (companyId, years = '', size = '', page = '', filters = []) =>
		get(`/company/users?companyId=${companyId}${years && '&years=' + years}${size && '&size=' + size}${page && '&page=' + page}${filters && '&filters=' + JSON.stringify(filters)}`),
	userListOne: (companyId, userIds, years = '') => get(`/company/users?companyId=${companyId}&users=${userIds}&years=${years}`),
	/**
	 * 퇴사자 목록
	 * @param companyId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	leaveList: (companyId) => get('/company/leave?companyId=' + companyId),

	/**
	 * 퇴사 하기
	 * @method PUT
	 * @param {INTEGER} companyId
	 * @param userId
	 */
	leave: (companyId, userId = null) => put('/company/leave', { companyId, userId }),

	/**
	 * 퇴사 하기
	 * @method PUT
	 * @param {INTEGER} companyId
	 * @param userId
	 */
	deleteLeaveData: (companyId, userId) => remove(`/company/leave?companyId=${companyId}&userId=${userId}`),

	/**
	 * 현재 회사 선택
	 * @method PUT
	 */
	selectCompany: (companyId) => put('/company/select', { companyId }),
	/**
	 * 관리자 정보 수정하기
	 * @method PUT
	 * @param {INTEGER} companyId
	 * @param {ARRAY[INTEGER]} userId
	 */

	modifyAdminList: (companyId, userId) => put('/company/admin', { companyId, userId }),
	/**
	 * 휴가유형 정보 New
	 * @param {*} companyId
	 */
	//getRestType: (companyId) => get('/restday/type?companyId=' + companyId),
	getRestType: (companyId) => get(`/restday/${companyId}`),
	/**
	 * 휴가 신청 휴가유형 정보 New
	 * @param {Object} param = {
	 * @param {INTEGER} companyId
	 * @param {Array} typeArray
	 */
	getUserRestType: (param) => post(`/user/restday`, param),
	/**
	 * 근무유형 정보 New
	 * @method GET
	 * @param {INTEGER} companyId
	 */

	getWorkType: (companyId) => get(`/restDay/work/${companyId}`),
	/**
	 * 공휴일
	 * @method GET
	 * @param {INTEGER} companyId
	 */

	getHoliday: (companyId) => get('/holyday?companyId=' + companyId),

	/**
	 * 공휴일
	 * @method GET
	 * @param {INTEGER} companyId
	 */

	getWorksystem: (companyId) => get('/worksystem?companyId=' + companyId),
	/**
	 * 회사 오너아이디로 사용기한 출력하기
	 * @method GET
	 * @param {INTEGER} ownerUserId
	 */

	getPeriodOfUse: (ownerUserId) => get('/company/getperiodofuse?ownerUserId=' + ownerUserId),

	/**
	 * 공휴일
	 * @method GET
	 * @param {INTEGER} companyId
	 */

	saveGroup: (group) => post('/workgroup/save2', group),
	/**
	 * 근무장소 추가
	 * @method POST
	 *
	 */
	addWorkingArea: (area) => post('/area', area),
	/**
	 * 근무장소 수정
	 * @method POST
	 * @param {INTEGER} areaId
	 *
	 */
	editWorkingArea: (areaId) => put('/area', areaId),
	/**
	 * 근무지 목록
	 * @method GET
	 * @param {INTEGER} companyId
	 */

	getWorkingArea: (companyId, userId) => get(`/area/id?companyId=${companyId}${userId !== undefined ? '&userId=' + userId : ''}`),
	/**
	 * 근무장소 삭제
	 * @method DELETE
	 * @param {INTEGER} areaId
	 *
	 */
	deleteWorkingArea: (areaId) => remove('/area/id?areaId=' + areaId),
	/**
	 * 근무장소 검색
	 * @method GET
	 * @param {INTEGER} areaId
	 *
	 */
	getById: (areaId) => get('/area/areaid?areaId=' + areaId),
	/**
	 * 근무제 검색
	 * @method GET
	 * @param {INTEGER} workSystemId
	 *
	 */
	getWorkSystemById: (workSystemId) => get('/area/worksystemid?workSystemId=' + workSystemId),
	/**
	 * 연차 정보
	 * @param {INTEGER} companyId
	 * @param {INTEGER} userId
	 * @returns
	 */
	restdayInfo: (restday) => get(`/restday/getRestInfo?${restday ? new URLSearchParams(restday).toString() : ''}`),
	/**
	 * 중복 로그인 방지 스위치
	 * @returns
	 */
	securityDevice: (companyId, securityDevice) => get(`/company/securityDevice?companyId=${companyId}&securityDevice=${securityDevice}`),
	/**
	 * 쿠폰전체출력
	 * @method GET
	 */
	getCoupon: () => get('/coupon/get'),
	/**
	 * 샘플 다운로드
	 */
	sampleDownload: (type) => get('/userCompany/sampleDownload?type=' + type),
	/**
	 * 직원초대 관리자
	 */
	joinRequestAdmin: (invate) => post('/userCompany/joinRequestAdmin', invate),
	/**
	 * 엑셀 자동 가입 후 계정정보 메일 통지
	 */
	joinCompanyDirect: (invate) => post('/userCompany/joinCompanyDirect', invate),
	/**
	 * 기사용 휴가 업로드
	 */
	uploadRestXlsx: (data) => post('/userCompany/uploadRestXlsx', data) /**
	/**
	 * 직원등록 관리자 목록
	 */,
	memberAddList: (companyId) => get('/userCompany/registList?companyId=' + companyId),
	/**
	 * 직원초대 관리자 목록
	 */
	joinRequestAdminList: (companyId) => get('/userCompany/joinRequestAdminList?companyId=' + companyId),
	/**
	 * 직원초대 관리자 재발송
	 */
	joinRequestReply: (invateId) => get('/userCompany/joinRequestReply?id=' + invateId),
	/**
	 * 직원초대 관리자 삭제
	 */
	joinRequestRemove: (invateId) => get('/userCompany/joinRequestRemove?id=' + invateId),
	/**
	 * 쿠폰 추가
	 * @method POST
	 *
	 */
	registCoupon: (couponSettings) => post('/coupon', couponSettings),
	/**
	 * 쿠폰 수정
	 * @method PUT
	 * @param {OBJECT} couponSettings
	 *
	 */
	modifyCoupon: (couponSettings) => put('/coupon/modify', couponSettings),

	/**
	 * 쿠폰 삭제
	 * @method DELETE
	 * @param {INTEGER} couponId
	 *
	 */
	deleteCoupon: (couponId) => remove('/coupon/id?couponId=' + couponId),
	/**
	 * 쿠폰 상세
	 * @method GET
	 * @param {INTEGER} couponId
	 */
	detailCoupon: (couponId) => get('/coupon/detail?couponId=' + couponId),
	/* 요청관리 관리자 */
	getAdminRequestList: (compnayId) => get(`/request/adminList/${compnayId}`),
	getAdminRequestList2: (info) => get(`/request/adminListRequest?${info ? new URLSearchParams(info).toString() : ''}`),
	/**
	 * 사용자 쿠폰 입력
	 * @method PUT
	 * @param {OBJECT} couponCode
	 *
	 */
	usecoupon: (couponCode) => put('/coupon/usecoupon', couponCode),
	/**
	 * 쿠폰 상세
	 * @method GET
	 * @param {INTEGER} couponId
	 */
	detailCouponHistory: (couponId) => get('/couponhistory/detail?couponId=' + couponId),
	/**
	 * 혜택 리스트
	 * @method GET
	 *
	 */
	getBenefitsList: () => get('/benefit/get'),
	/**
	 * 유저 쿠폰내역
	 * @method GET
	 * @param {INTEGER} userId
	 */
	getUsedCopon: (userId) => get('/coupon/user?userId=' + userId),
	/**
	 * 쿠폰 혜택 찾기
	 * @method GET
	 * @param {INTEGER} benefitsId
	 */
	getBenefits: (benefitsId) => get('/benefit/get?benefitsId=' + benefitsId),
	/**
	 * 사용자정보 수정
	 * @method PUT
	 * @param {OBJECT} userProfile
	 *
	 */
	modify: (userProfile) => put('/usercompany/modify', userProfile),
	/**
	 * 결재 승인 (관리자)
	 */
	approvalRequest: (approval) => post('/request/approvalRequest', approval),
	/**
	 * 대시보드 통계 (관리자)
	 */
	attendReportToday: (companyId) => get('/request/attendReportToday?companyId=' + companyId),
	/**
	 * 근무 신청
	 * @param {*} workday
	 * @returns
	 */
	workdayRequest: (workday) => post('/attend/workday-request', workday),
	/**
	 * 휴가 정책 설정
	 */
	vaction: (vaction) => put(`/company/vaction`, vaction),
	/**
	 * 회사 인원
	 * @param {*} companyId
	 * @returns
	 */
	getCompanyUserList: (companyId) => get('/company/usersLists?companyId=' + companyId),
	/**
	 * 동료 근무 기록
	 * @param {*} companyId
	 * @param {*} departId
	 * @returns
	 */
	getWorkLogsByDepart: (companyId, departId, sdate, edate) => get(`/attend/log/depart/${companyId}/${departId}?sdate=${sdate}&edate=${edate}`),
	dashboardAttendLog: (companyId) => get('/attend/dashboard/attendLog?companyId=' + companyId),
	dashboardAttendLogsGroups: (companyId, groupId) => get('/attend/dashboard/attendLogsGroups?companyId=' + companyId + '&groupId=' + groupId),
	workTimeOver: (companyId, time, date) => get(`/company/workTime/${companyId}/${time}?date=${date}`),
	approvalCheck: (companyId, data) => put(`/approval/${companyId}/check`, data),
	//getEApproval: (companyId, userId) => get(`/e-approval/${companyId}/${userId}`),
	getEApproval: (companyId, userId, size, page) => get(`/e-approval/${companyId}/${userId}?${size && '&size=' + size}${page && '&page=' + page}`),
	getAbsenteeAll: (companyId) => get('/absentee?companyId=' + companyId),
	addAbsentee: (absenteeData) => post(`/absentee`, absenteeData),
	modifyAbsentee: (absenteeData) => put(`/absentee`, absenteeData),
	deleteAbsentee: (absenteeId) => remove('/absentee?absenteeId=' + absenteeId),
	/**
	 * 전자결재 함수
	 * @param {serial} id        request PK ID
	 * @param {int} companyId 회사 PK ID
	 * @param {int} userId      결재 사용자 정보
	 * @param {int} y         결재 순서 y
	 * @param {int} x         결재 순서 x
	 * @param {string} state     결재 상태
	 */
	approval: (request) => post('/request/approval', request),
	who: (companyId, requestId) => get(`/e-approval/who/${companyId}/${requestId}`),
	/**
	 * 신청 상세
	 * @returns
	 */
	requestDetail: (request) => get('/request/detail?id=' + request),
};
