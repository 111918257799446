import { types } from 'mobx-state-tree';
import moment from 'moment';
import { demoPages } from '../menu';
import AuthService from '../services/AuthService';

export const User = types
	.model('User', {
		id: types.optional(types.number, 0),
		name: types.maybe(types.string),
		email: types.maybe(types.string),
		profile: types.maybe(types.string),
		phone: types.maybe(types.string),
		userRank: types.maybe(types.string),
		userDepartment: types.maybe(types.string),
		loginType: types.maybe(types.string),
		createdAt: types.maybe(types.string),
		udpatedAt: types.maybe(types.string),
		currentCompanyId: types.maybe(types.number),
		permission:  types.maybe(types.string),
		permissions:  types.optional(types.string, "{}"),
	})
	.views((self) => ({
		get me() {
			try{
				return { 
					...self, 
					profile: self.profile?.includes('http') ? self?.profile || null : (self.profile ? '//work.pinat.co.kr' + self.profile : null),
					permissions: JSON.parse(self.permissions) 
				};
			} catch (e) {
				// console.log(e);
				window.location.replace("/"+demoPages.login.path)
			}
		},
	}))
	.actions((self) => ({
		async authMe() {
			try { 
				const response = await AuthService.me();
				if (response.data) {
					// console.log("AuthService.me", response.data)
					self.setData(response.data);
					return response.data
				} else {
					if(window.location.pathname !== demoPages.login.path) {
						// alert('세션이 종료되어 로그아웃 됩니다.');
						window.location.href = '/'+demoPages.login.path;
					}
				}
			} catch (err) {
				if(window.location.pathname !== demoPages.login.path) {
					// alert('세션이 종료되어 로그아웃 됩니다.');
					window.location.href = '/'+demoPages.login.path;
				}
			}
		},

		async setRank(rank) {
			self.userRank = rank;
		},

		async setDepartment(depart) {
			self.userDepartment = depart;
		},

		async setData(data) {
			//console.log(data);
			self.id = data?.id || self.id;
			self.name = data?.name || self.name;
			self.email = data?.email || self.email;
			self.profile = data?.profile || self.profile;
			self.phone = data?.phone || self.phone;
			self.userRank = data?.userRank || self.userRank;
			self.userDepartment = data?.userDepartment || self.userDepartment;
			self.loginType = data?.loginType || '';
			self.permissions = data?.permissions || self.permissions;
			self.permission = data?.permission || self.permission;
			self.createdAt = moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss');
			self.udpatedAt = moment(data?.udpatedAt).format('YYYY-MM-DD HH:mm:ss');
			if (data.currentCompanyId) self.currentCompanyId = data?.currentCompanyId;
		},

		async logout() {
			self.id = 0;
			self.name = '';
			self.email = '';
			self.profile = '';
			self.phone = '';
			self.userRank = '';
			self.userDepartment = '';
			self.loginType = '';
			self.createdAt = '';
			self.udpatedAt = '';
			self.currentCompanyId = 0;
			self.permission = '';
			self.permissions = '{}';
		},
	}));

export default User;
